import { useUser, type UserType } from "@dzangolab/react-user";

export const hasRole = (user: UserType | null, role: string): boolean => {
  if (!user) {
    return false;
  }

  return user.roles.includes(role);
};

export const useHasRole = (role: string): boolean => {
  const { user } = useUser();

  return hasRole(user, role);
};
