import { Tag } from "@dzangolab/react-ui";
import { Release, ReleaseApp, WORKSPACE_RELEASE_STATUS } from "core";

import { STATUS_TAG_COLOR } from "../../../constants";

interface Properties {
  release: Release;
  module: ReleaseApp;
}

const ReleaseModuleTitle: React.FC<Properties> = (properties) => {
  const { module, release } = properties;

  const getAppStatusColor = (status?: string | null) => {
    if (
      status === WORKSPACE_RELEASE_STATUS.STOPPED ||
      status === WORKSPACE_RELEASE_STATUS.STOPPING
    )
      return STATUS_TAG_COLOR.STOPPED;
    if (status === WORKSPACE_RELEASE_STATUS.ERROR)
      return STATUS_TAG_COLOR.ERROR;

    return STATUS_TAG_COLOR.RUNNING;
  };

  return (
    <span className="release-module-title">
      <p className="subtitle">
        {release.type?.slug ?? ""}/{release.name}
      </p>
      <div className="title">
        <h1>{module.name}</h1>
        {module.status && (
          <Tag
            color={getAppStatusColor(module.status)}
            label={module.status ?? ""}
          />
        )}
      </div>
    </span>
  );
};

export default ReleaseModuleTitle;
