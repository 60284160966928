import { useUser } from "@dzangolab/react-user";

import { SettingsAdvanced, SettingsGeneral } from "./components";
import { USER_ROLES } from "../../../constants";
import { hasRole } from "../../../libs";

const GeneralSettings = () => {
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  return (
    <>
      <div className="general-settings">
        <SettingsGeneral />
      </div>

      {isDeveloper && (
        <div className="advanced-settings">
          <SettingsAdvanced />
        </div>
      )}
    </>
  );
};

export default GeneralSettings;
