import { useTranslation } from "@dzangolab/react-i18n";
import { NavItemType } from "@dzangolab/react-ui";
import {
  logout,
  UserEnabledSidebarHeaderLayout,
  useUser,
} from "@dzangolab/react-user";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ROUTES, SESSION_STORAGE_KEYS } from "../constants";
import baseApi from "../redux/apis/base-api";
import { resetSelectedWorkspaces } from "../redux/SelectedWorkspacesSlice";
import { WebSocketProvider } from "../WebSocketContext";
import { Header } from "./components";
import { Footer } from "./components/Footer";
import OverlaySidebar from "./components/OverlaySidebar";

export const BasicLayout = (): JSX.Element => {
  const { setUser } = useUser();
  const { t } = useTranslation("app");
  const sidebarReference = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (dispatch: any) => {
    dispatch(resetSelectedWorkspaces());
    dispatch(baseApi.util.resetApiState());
  };

  const signout = async () => {
    if (await logout()) {
      setUser(null);
      sessionStorage.removeItem(
        SESSION_STORAGE_KEYS.WORKSPACE_REDIRECTED_AFTER_LOGIN,
      );
      handleLogout(dispatch);
      toast.success(
        t("logout.message", {
          ns: "user",
        }),
      );
    }
  };

  const userMenu: NavItemType[] = [
    {
      icon: "pi pi-lock",
      label: t("user:changePassword.title"),
      onClick: () => navigate(ROUTES.CHANGE_PASSWORD),
    },
    {
      icon: "pi pi-user",
      label: t("user:userMenu.profile"),
      onClick: () => navigate(ROUTES.PROFILE),
    },
    {
      icon: "pi pi-power-off",
      label: t("header.menu.logout"),
      onClick: signout,
    },
  ];

  const toggleSidebar = () => {
    if (!sidebarReference.current) {
      return;
    }
    sidebarReference.current.classList.toggle("open");
  };

  return (
    <WebSocketProvider>
      <UserEnabledSidebarHeaderLayout
        children={
          <>
            <Outlet />
            <Footer />
          </>
        }
        customHeader={
          <Header toggleSidebar={toggleSidebar} userMenu={userMenu} />
        }
        customSidebar={<OverlaySidebar sidebarRef={sidebarReference} />}
      />
    </WebSocketProvider>
  );
};
