import { FileExtended, FileUpload } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { ServiceFixtures, Workspace } from "core";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useUploadFixturesMutation } from "../redux/apis/fixtures";

interface FixturesOption {
  value: ServiceFixtures;
  label: string;
}

interface FixturesPickerProperties {
  errorMessage?: string;
  fixtures: ServiceFixtures[];
  initialValue?: number | null;
  isDisabled?: boolean;
  label: string;
  workspace?: Workspace;
  onSelect: (fixtures: ServiceFixtures) => void;
}

const FixturesPicker: React.FC<FixturesPickerProperties> = ({
  errorMessage,
  fixtures,
  initialValue,
  isDisabled,
  label,
  onSelect,
  workspace,
}) => {
  const { t } = useTranslation("fixturesPicker");
  const dropdownReference = useRef<Dropdown>(null);

  const [selectedFixtures, setSelectedFixtures] = useState<
    ServiceFixtures | undefined
  >();
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadFixtures, { isLoading }] = useUploadFixturesMutation();

  const handleFixturesChange = (event: DropdownChangeEvent) => {
    const newFixtures = event.value as ServiceFixtures;

    setSelectedFixtures(newFixtures);
    onSelect(newFixtures);
  };

  const handleUpload = async (files: FileExtended[]) => {
    if (!workspace || files.length === 0) return;

    const formData = new FormData();

    formData.append("file", files[0]);

    try {
      const response = await uploadFixtures({
        input: formData,
        workspaceId: workspace.id,
      }).unwrap();

      if (response) {
        const { success, error } = response;

        if (error) {
          toast.error(error);
        } else if (success) {
          toast.success(t("form.message.success"));

          setIsUploadModalVisible(false);
        }
      }
    } catch (error: any) {
      const errorMessage =
        error?.data?.error ||
        error?.error ||
        error?.message ||
        t("form.message.error");

      toast.error(errorMessage);
    }
  };

  const closeDropdown = () => {
    (dropdownReference.current as any)?.hide();
  };

  const fixturesOptions: FixturesOption[] = fixtures.map((data) => ({
    value: data,
    label: data.filename,
  }));

  const renderUploadDialog = () => (
    <Modal
      className="fixtures-upload-modal"
      header={t("modal.title")}
      onHide={() => setIsUploadModalVisible(false)}
      visible={isUploadModalVisible}
    >
      <FileUpload
        dropzoneMessage={t("modal.dropdown-message")}
        dropzoneOptions={{
          accept: {
            "application/sql": [".sql"],
            "application/gzip": [".gz"],
          },
        }}
        multiple={false}
        name="files"
        onCancel={() => setIsUploadModalVisible(false)}
        onUpload={handleUpload}
        uploadButtonOptions={{
          loading: isLoading,
        }}
      />
    </Modal>
  );

  const PanelFooter = () => (
    <div className="fixtures-picker-panel-footer">
      <Link
        onClick={(event) => {
          event.preventDefault();
          closeDropdown();
          setIsUploadModalVisible(true);
        }}
        to=""
      >
        {t("upload-btn-label")}
      </Link>
    </div>
  );

  useEffect(() => {
    const initialFixtures = fixtures.find(({ id }) => initialValue === id);

    setSelectedFixtures(initialFixtures);
  }, [fixtures, initialValue]);

  return (
    <div className="flex-group">
      <div className="flex-group-item">
        <label>{label}</label>
        <div className="fixtures-picker-wrapper">
          <Dropdown
            className="fixtures-picker"
            disabled={isDisabled}
            emptyMessage={t("form.dropdown.empty-message")}
            onChange={handleFixturesChange}
            optionLabel="label"
            options={fixturesOptions}
            panelFooterTemplate={PanelFooter}
            placeholder={t("form.dropdown.placeholder")}
            ref={dropdownReference}
            value={selectedFixtures}
          />
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          {renderUploadDialog()}
        </div>
      </div>
    </div>
  );
};

export default FixturesPicker;
