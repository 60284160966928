import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Glossary, SupportedLocales } from "core";
import { toast } from "react-toastify";
import { z } from "zod";

import GlossaryFormFields from "./GlossaryFormFields";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import {
  useCreateGlossaryMutation,
  useUpdateGlossaryMutation,
} from "../../../../redux/apis/glossary";

interface Properties {
  glossary?: Glossary;
  workspaceId: number;
  onFormSubmitSuccess: () => void;
  supportedLocales: SupportedLocales[];
}

const GlossaryForm: React.FC<Properties> = (properties) => {
  const { t } = useTranslation("glossary");
  const { onFormSubmitSuccess, workspaceId, supportedLocales, glossary } =
    properties;

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [createGlossary, { isLoading: isCreatingGlossary }] =
    useCreateGlossaryMutation();

  const [updateGlossary, { isLoading: isUpdatingGlossary }] =
    useUpdateGlossaryMutation();

  const onFormSubmit = async (data: any) => {
    if (glossary) {
      let response = await updateGlossary({
        glossaryId: glossary.id,
        data: {
          i18n: glossary.i18n,
          mainLocale: data.mainLocale,
          supportedLocales: data.supportedLocales,
        },
      });

      if ("error" in response) {
        toast.error(t("message.error.default"));
      } else {
        toast.success(t("message.update-success"));
      }
    } else {
      let response = await createGlossary({
        workspaceId,
        data: {
          i18n: [
            {
              name: `${currentWorkspace.name}'s glossary`,
              description: `Glossary for ${
                currentWorkspace.name ?? "Workspace"
              }`,
              locale: "en",
            },
          ],
          mainLocale: data.mainLocale,
          supportedLocales: data.supportedLocales,
        },
      });

      if ("error" in response) {
        toast.error(t("message.error.default"));
      } else {
        toast.success(t("message.success"));
      }
    }

    onFormSubmitSuccess();
  };

  const formValidationSchema = z.object({
    mainLocale: z.string().min(1),
    supportedLocales: z.array(z.string()),
  });

  const defaultValues = {
    mainLocale: "",
    supportedLocales: [],
  };

  const getInitialValues = () => {
    if (glossary) {
      const mainLocale = glossary.locales.find((locale) => locale.mainLocale);

      return {
        mainLocale: mainLocale?.localeCode ?? "",
        supportedLocales: glossary.locales.map((locale) => locale.localeCode),
      };
    }

    return defaultValues;
  };

  return (
    <Provider
      className="glossary-entry-form"
      defaultValues={defaultValues}
      onSubmit={onFormSubmit}
      validationSchema={formValidationSchema}
      values={getInitialValues()}
    >
      <GlossaryFormFields
        isSubmitting={isCreatingGlossary || isUpdatingGlossary}
        supportedLocales={supportedLocales}
      />
    </Provider>
  );
};

export default GlossaryForm;
