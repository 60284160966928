import { useTranslation } from "@dzangolab/react-i18n";
import { Page, TabView } from "@dzangolab/react-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AttachedReleases, ServiceForm, ServiceLogs } from "./components";
import BackButton from "../../components/BackButton";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import {
  useLazyGetServiceBySecondaryIdentifierQuery,
  useLazyGetServicePresetTemplatesQuery,
} from "../../redux/apis/services";

const DetailedService = () => {
  const params = useParams();
  const { t } = useTranslation("services");
  const [pageTitle, setPageTitle] = useState<string>();

  const [getServiceBySecondaryIdentifier, { data: service, isFetching }] =
    useLazyGetServiceBySecondaryIdentifierQuery();
  const [fetchServicePresetTemplates, { data: servicePresetTemplates }] =
    useLazyGetServicePresetTemplatesQuery();

  const { workspace } = useCurrentWorkspace();

  const { serviceSecondaryId } = params;

  const allTabs = [
    {
      label: t("tabs.settings"),
      key: "settings",
      children: (
        <ServiceForm
          onCancel={() => {}}
          onServiceNameChange={(name) => {
            setPageTitle(name);
          }}
          service={service}
          servicePresetTemplates={servicePresetTemplates ?? []}
        />
      ),
      closable: false,
    },
    {
      label: t("tabs.releases"),
      key: "releases",
      children: service ? (
        <AttachedReleases service={service} workspace={workspace} />
      ) : (
        <></>
      ),
      closable: false,
    },
    {
      label: t("tabs.logs"),
      key: "logs",
      children: service ? <ServiceLogs service={service} /> : <></>,
      closable: false,
    },
  ];

  useEffect(() => {
    if (serviceSecondaryId && workspace) {
      getServiceBySecondaryIdentifier({
        secondaryId: serviceSecondaryId,
        workspaceId: workspace.id,
      });

      fetchServicePresetTemplates({ workspaceId: workspace.id });
    }
  }, [
    fetchServicePresetTemplates,
    getServiceBySecondaryIdentifier,
    serviceSecondaryId,
    workspace,
  ]);

  useEffect(() => {
    if (service) {
      setPageTitle(service.name);
    }
  }, [service]);

  return (
    <Page
      breadcrumb={<BackButton />}
      className="detailed-service-page"
      loading={isFetching}
      title={pageTitle ?? ""}
    >
      <TabView
        activeKey={"settings"}
        id={`services=${serviceSecondaryId}`}
        persistState={false}
        tabs={allTabs}
        visibleTabs={["settings", "releases", "logs"]}
      />
    </Page>
  );
};

export default DetailedService;
