import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  ConfirmationModal,
  TDataTable as DataTable,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { SERVICE_STATUS, Service, getKeyFromValue } from "core";
import { LogsIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ServiceFormModal } from "./components";
import { USER_ROLES } from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../../libs";
import {
  useLazyDeployServiceQuery,
  useLazyGetServicePresetTemplatesQuery,
  useLazyGetServicesQuery,
  useLazyStopServiceQuery,
  useLazyGetServiceLogsQuery,
} from "../../../redux/apis/services";

const Services = () => {
  const { t } = useTranslation("services");

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const navigate = useNavigate();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [isLogsModalVisible, setIsLogsModalVisible] = useState(false);
  const [isStopModalVisible, setIsStopModalVisible] = useState(false);
  const [isStartModalVisible, setIsStartModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<Service>();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsLogsModalVisible(false);
    setIsStartModalVisible(false);
    setIsStopModalVisible(false);
    setIsModalVisible(false);
    setSelectedService(undefined);
  };

  const [fetchServiceLogs, { data: logsData, isFetching: isFetchingLogs }] =
    useLazyGetServiceLogsQuery();

  const [fetchServices, { data: workspaceServices, isFetching: isLoading }] =
    useLazyGetServicesQuery();

  const [stopService, { isFetching: isStoppingService }] =
    useLazyStopServiceQuery();
  const [fetchServicePresetTemplates, { data: servicePresetTemplates }] =
    useLazyGetServicePresetTemplatesQuery();

  const [stoppingServiceIds, setStoppingServiceIds] = useState<number[]>([]);
  const [startingServiceIds, setStartingServiceIds] = useState<number[]>([]);

  const stopActionHandler = async (service: Service) => {
    closeModal();
    setStoppingServiceIds([...stoppingServiceIds, service.id]);

    const response = await stopService({
      id: service.id,
      workspaceId: service.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.stop"));

      return;
    }

    setStoppingServiceIds((ids) => ids.filter((id) => id !== service.id));
  };

  const editActionHandler = (service: Service) => {
    setSelectedService(service);
    openModal();
  };

  const displayLogsActionHandler = (service: Service) => {
    setSelectedService(service);
    setIsLogsModalVisible(true);
  };

  useEffect(() => {
    if (currentWorkspace) {
      fetchServices({ workspaceId: currentWorkspace.id });
      fetchServicePresetTemplates({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchServicePresetTemplates, fetchServices]);

  useEffect(() => {
    if (selectedService && isLogsModalVisible) {
      fetchServiceLogs({
        workspaceId: selectedService.workspaceId,
        serviceId: selectedService.id,
      });
    }
  }, [selectedService, isLogsModalVisible]);

  const [deployService, { isFetching: isDeployingService }] =
    useLazyDeployServiceQuery();

  const buildActionHandler = async (selectedService: Service) => {
    closeModal();
    setStartingServiceIds([...startingServiceIds, selectedService.id]);

    const response = await deployService({
      id: selectedService.id,
      workspaceId: selectedService.workspaceId,
    });

    if (response.error) {
      toast.error(t("messages.error.start"));

      return;
    }

    setStartingServiceIds((ids) =>
      ids.filter((id) => id !== selectedService.id),
    );
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => (
        <>
          <span>{original.name}</span>
          {original.isPublic &&
            original.statusId === 2 &&
            original.publicUrl && (
              <a
                className="service-name-link"
                href={original.publicUrl}
                rel="noreferrer"
                target="_blank"
              >
                <i className="pi pi-external-link"></i>
              </a>
            )}
        </>
      ),
    },
    {
      accessorKey: "system",
      header: t("table.columns.image"),
      width: "15rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const matchingService = servicePresetTemplates?.find(
          ({ image }) => image === original.image,
        );

        if (!matchingService) return "-";

        const version = matchingService.versions.find(
          ({ value }) => value === original.imageVersion,
        );

        return `${matchingService.name}${version ? ` (${version.label})` : ""}`;
      },
    },
    {
      accessorKey: "statusId",
      accessorFn: (row) => row.statusId ?? undefined,
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAStatus =
          getKeyFromValue(SERVICE_STATUS, rowA.original.statusId) || "";
        const rowBStatus =
          getKeyFromValue(SERVICE_STATUS, rowB.original.statusId) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const status = getKeyFromValue(SERVICE_STATUS, original.statusId) || "";

        return (
          <div className="status">
            <div className="status-text">{status}</div>
            {isDeveloper && (
              <>
                {original.statusId === SERVICE_STATUS.RUNNING ||
                original.statusId === SERVICE_STATUS.STARTING ? (
                  <Button
                    disabled={
                      original.statusId !== SERVICE_STATUS.RUNNING ||
                      (stoppingServiceIds.includes(original.id) &&
                        isStoppingService)
                    }
                    iconLeft="pi pi-stop-circle"
                    onClick={() => {
                      setSelectedService(original);
                      setIsStopModalVisible(true);
                    }}
                    rounded
                    severity="danger"
                    variant="textOnly"
                  />
                ) : (
                  <Button
                    disabled={
                      original.statusId === SERVICE_STATUS.STOPPING ||
                      (startingServiceIds.includes(original.id) &&
                        isDeployingService)
                    }
                    iconLeft="pi pi-play-circle"
                    onClick={() => {
                      setSelectedService(original);
                      setIsStartModalVisible(true);
                    }}
                    severity="success"
                    variant="textOnly"
                  />
                )}
              </>
            )}
          </div>
        );
      },
    },
    ...(isDeveloper
      ? [
          {
            id: "action",
            width: "5rem",
            header: () => <i className="pi pi-cog"></i>,
            dataType: "other",
            cell: ({ row: { original } }: { row: { original: any } }) => (
              <div className="actions">
                <Button
                  iconLeft="pi pi-pen-to-square"
                  onClick={() => {
                    navigate(
                      `/workspaces/${currentWorkspace.secondaryIdentifier}/services/${original.secondaryIdentifier}`,
                    );
                    editActionHandler(original);
                  }}
                  variant="textOnly"
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="settings-services">
      <div className="title-wrapper">
        {isDeveloper && (
          <Button onClick={openModal}>
            <i className="pi pi-plus"></i>
          </Button>
        )}
      </div>

      <DataTable
        className="workspace-services-table"
        columns={columns}
        data={workspaceServices || []}
        emptyTableMessage={t("table.empty")}
        initialSorting={[
          {
            id: "name",
            desc: false,
          },
        ]}
        isLoading={isLoading}
      />

      <ServiceFormModal
        onHide={closeModal}
        servicePresetTemplates={servicePresetTemplates ?? []}
        visible={isModalVisible}
      />

      <ConfirmationModal
        accept={() => stopActionHandler(selectedService as Service)}
        acceptButtonOptions={{
          label: t("confirmation.stop.actions.stop"),
          severity: "danger",
        }}
        cancelButtonOptions={{
          label: t("confirmation.stop.actions.cancel"),
        }}
        header={t("confirmation.stop.header")}
        message={t("confirmation.stop.message", {
          service: selectedService?.name,
        })}
        onHide={closeModal}
        visible={isStopModalVisible}
      />

      <ConfirmationModal
        accept={() => buildActionHandler(selectedService as Service)}
        acceptButtonOptions={{
          label: t("confirmation.start.actions.start"),
        }}
        cancelButtonOptions={{
          label: t("confirmation.start.actions.cancel"),
        }}
        header={t("confirmation.start.header")}
        message={t("confirmation.start.message", {
          service: selectedService?.name,
        })}
        onHide={closeModal}
        visible={isStartModalVisible}
      />
    </div>
  );
};

export default Services;
