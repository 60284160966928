import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import { useCurrentWorkspace } from "../../../../../hooks/UseCurrentWorkspace";
import {
  useAddReleaseAppMutation,
  useDeleteReleaseAppMutation,
  useLazyGetReleaseAppEnginePresetTemplatesQuery,
  useUpdateReleaseAppMutation,
} from "../../../../../redux/apis/release";
import AddReleaseAppFormFields from "../../AddReleaseApp/ReleaseAppFormFields";

interface IProperties {
  releaseModule: ReleaseApp;
}

const ModuleDetails: React.FC<IProperties> = (properties) => {
  const { releaseModule } = properties;
  const { t } = useTranslation("appOverview");
  const navigate = useNavigate();

  const { workspace, release, refetchWorkspaceAndRelease } =
    useCurrentWorkspace();
  const [triggerAdd, { isLoading: isCreating, error }] =
    useAddReleaseAppMutation();
  const [triggerUpdate, { isLoading: isUpdating }] =
    useUpdateReleaseAppMutation();
  const [getEnginePresets, { data: enginePresets }] =
    useLazyGetReleaseAppEnginePresetTemplatesQuery();
  const [deleteReleaseApp] = useDeleteReleaseAppMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onAppDelete = async () => {
    if (!releaseModule || !release) return;

    setShowDeleteModal(false);

    await deleteReleaseApp({ releaseId: release.id, appId: releaseModule.id })
      .then(() => {
        refetchWorkspaceAndRelease();
        navigate(
          `/workspaces/${workspace.secondaryIdentifier}/releases/${release.secondaryIdentifier}`,
        );
        toast.success(t("release-app.delete.message.success"));
      })
      .catch(() => {
        toast.error(t("release-app.delete.message.error"));
      });
  };

  useEffect(() => {
    if (!isCreating && error) {
      toast.error(t("release-app.add.error"));
    }
  }, [error, isCreating, t]);

  useEffect(() => {
    getEnginePresets();
  }, [getEnginePresets]);

  const validationSchema = z.object({
    branch: z.string({
      required_error: t("release-app.add.form.validations.branch.required"),
    }),
    buildScript: z
      .string()
      .min(1, t("release-app.add.form.validations.buildScript.required")),
    description: z.string().nullable(),
    envVars: z
      .object({
        name: z
          .string()
          .min(1, t("release-app.add.form.validations.name.required")),
        value: z.union([z.string(), z.number(), z.boolean()]),
      })
      .array(),
    engineName: z
      .string()
      .min(1, "release-app.add.form.validations.engineName.required"),
    engineType: z
      .string()
      .min(1, "release-app.add.form.validations.engineType.required"),
    engineVersion: z
      .string()
      .min(1, "release-app.add.form.validations.engineVersion.required"),
    packageManager: z
      .string()
      .min(1, "release-app.add.form.validations.packageManager.required"),
    isPublic: z.boolean().nullable(),
    name: z
      .string()
      .min(1, t("release-app.add.form.validations.name.required")),
    port: z.number({
      invalid_type_error: t("release-app.add.form.validations.port.required"),
      required_error: t("release-app.add.form.validations.port.required"),
    }),
    proxyConfig: z
      .string()
      .refine((value) => {
        try {
          if (value) {
            JSON.parse(value);
          }

          return true;
        } catch (_) {
          return false;
        }
      }, t("release-app.add.form.validations.proxyConfig.invalid"))
      .transform((value) => (value ? JSON.parse(value) : null))
      .nullable(),
    repositoryId: z.number({
      invalid_type_error: t(
        "release-app.add.form.validations.repositoryId.required",
      ),
      required_error: t(
        "release-app.add.form.validations.repositoryId.required",
      ),
    }),
    runScript: z
      .string()
      .min(1, t("release-app.add.form.validations.runScript.required")),
    slug: z
      .string()
      .min(1, t("release-app.add.form.validations.slug.required")),
    sourcePath: z.string().nullable(),
    type: z
      .string()
      .min(1, t("release-app.add.form.validations.type.required"))
      .optional(),
  });

  const defaultValues = {
    branch: "",
    buildScript: "",
    envVars: [],
    extensionConfig: "",
    isPublic: false,
    name: "",
    proxyConfig: "",
    repositoryId: null,
    runScript: "",
    slug: "",
    sourcePath: "",
  };

  const getInitialValues = () => {
    if (!releaseModule) return defaultValues;

    const extensionConfig = releaseModule.extensionConfig;

    const proxyConfig = releaseModule.proxyConfig
      ? JSON.stringify(releaseModule.proxyConfig)
      : "";

    const envVariables = releaseModule.envVars
      ? Object.keys(releaseModule.envVars).map((key) => ({
          name: key,
          value:
            releaseModule.envVars[key] === null
              ? "null"
              : releaseModule.envVars[key],
        }))
      : [];

    return {
      branch: releaseModule.branch,
      buildScript: releaseModule.buildScript || "",
      envVars: envVariables,
      description: releaseModule.description || "",
      engineName: extensionConfig?.engine?.name || "",
      engineType: extensionConfig?.type || "",
      engineVersion: extensionConfig?.engine?.version || "",
      packageManager: extensionConfig?.packageManager || "",
      isPublic: releaseModule.isPublic || false,
      name: releaseModule.name,
      port: releaseModule.port,
      proxyConfig,
      repositoryId: releaseModule.repositoryId,
      runScript: releaseModule.runScript || "",
      slug: releaseModule.slug,
      sourcePath: releaseModule.sourcePath || "",
      type: releaseModule.type,
    };
  };

  const handleSubmit = async (data: z.infer<typeof validationSchema>) => {
    const envVariables = data.envVars.reduce(
      (result: Record<string, string>, { name, value }) => {
        result[name] = String(value);

        return result;
      },
      {},
    );

    if (releaseModule) {
      await triggerUpdate({
        id: releaseModule.id,
        data: {
          ...data,
          envVars: envVariables,
          releaseId: release.id,
        },
      })
        .then(() => {
          refetchWorkspaceAndRelease();
          toast.success(t("release-app.update.message.success"));
        })
        .catch(() => {
          toast.error(t("release-app.update.message.error"));
        });
    } else {
      triggerAdd({
        ...data,
        envVars: envVariables,
        releaseId: release.id,
        type: "app",
      });
    }
  };

  const navigateToBranchRules = (repositoryId: number) => {
    if (workspace && repositoryId) {
      const url = `/workspaces/${workspace.secondaryIdentifier}/repositories/${repositoryId}#branch-rules`;

      window.open(url, "_blank");
    }
  };

  return (
    <div className="module-details-tab">
      <Provider
        className="release-app-form"
        defaultValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <AddReleaseAppFormFields
          enginePresets={enginePresets ?? []}
          handleCancel={() => {}}
          handleDelete={() => setShowDeleteModal(true)}
          handleShowBranchRules={navigateToBranchRules}
          isLoading={isCreating || isUpdating}
          releaseApp={releaseModule}
          showDetailsForm={true}
          showEnvironmentForm={false}
          workspace={workspace}
        />
        <ConfirmationModal
          accept={onAppDelete}
          acceptButtonOptions={{
            label: t("release-app.delete.confirmation.buttonLabel.accept", {
              appModule: releaseModule.name,
            }),
            severity: "danger",
          }}
          cancelButtonOptions={{
            label: t("release-app.delete.confirmation.buttonLabel.cancel"),
          }}
          header={t("release-app.delete.confirmation.header", {
            appModule: releaseModule.name,
          })}
          message={t("release-app.delete.confirmation.message", {
            appModule: releaseModule.name,
          })}
          onHide={() => setShowDeleteModal(false)}
          visible={showDeleteModal}
        />
      </Provider>
    </div>
  );
};

export default ModuleDetails;
