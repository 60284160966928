import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Data, LoadingPage } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { Repository } from "core";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";

import { USER_ROLES } from "../../../constants";
import { hasRole } from "../../../libs";
import { useLazyValidateCredentialsQuery } from "../../../redux/apis/repositories";
import { isFetchBaseQueryError } from "../../../redux/util";
import { RepositoryForm } from "../components";

interface Properties {
  repository: Repository;
  setPageTitle: (title: string) => void;
}

export const GeneralInfo: React.FC<Properties> = ({
  repository,
  setPageTitle,
}) => {
  const { t } = useTranslation("repositoryDetails");

  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [currentRepository, setCurrentRepository] =
    useState<Repository>(repository);
  const [renderEdit, setRenderEdit] = useState(false);

  const [validateCredentials, { data, error, isError, isFetching }] =
    useLazyValidateCredentialsQuery();

  const [showToken, toggleShowToken] = useReducer(
    (previous) => !previous,
    false,
  );

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t("messages.success.copyRepoUrl"));
    } catch {
      toast.error(t("messages.error.copyFailed"));
    }
  };

  const maskToken = (token: string) => "*".repeat(token.length);

  const validateCredentialsHandler = () => {
    if (!repository.credential.username || !repository.credential.accessToken)
      return;

    validateCredentials({
      url: repository.url,
      credential: repository.credential,
    });
  };

  const toggleEditForm = () => {
    setRenderEdit(!renderEdit);
    setPageTitle(currentRepository.name);
  };

  const handleEdit = (editedRepository: Repository) => {
    setCurrentRepository(editedRepository);
    setPageTitle(editedRepository.name);
    setRenderEdit(false);
  };

  useEffect(() => {
    if (!isFetching && data?.ok) {
      toast.success(t("messages.validCredentials"));
    }
  }, [data, isFetching, t]);

  useEffect(() => {
    if (isError && isFetchBaseQueryError(error)) {
      const errorMessages: Record<string, string> = {
        AUTH_FAILURE: "messages.invalidCredential",
        FORBIDDEN_PERSONAL_ACCESS_TOKEN: "messages.forbiddenAccessToken",
        INSUFFICIENT_SCOPES: "messages.insufficientScopes",
        NO_PULL_PUSH_PERMISSION: "messages.noPullPushPermission",
        PERMISSION_DENIED: "messages.permissionDenied",
        REPO_NOT_FOUND: "messages.noRepositoryFound",
        TOKEN_EXPIRED: "messages.tokenExpired",
        UNAUTHORIZED_ACCESS: "messages.unauthorizedAccess",
        UNAUTHORIZED_ACCESS_GITHUB: "messages.unauthorizedAccessGithub",
      };

      const errorMessage = error.data as {
        error?: string;
        requiredScopes?: string[];
      };
      const messageKey =
        errorMessage?.error && errorMessages[errorMessage.error];

      if (errorMessage?.error === "INSUFFICIENT_SCOPES") {
        toast.error(
          t(messageKey || "form.messages.error.default", {
            scopes: errorMessage.requiredScopes?.join(", ") || "",
          }),
        );
      } else {
        toast.error(t(messageKey || "form.messages.error.default"));
      }
    }
  }, [isError, error, t]);

  return currentRepository ? (
    <div className="repository-detail-general">
      {isDeveloper && (
        <Button iconLeft={"pi pi-pen-to-square"} onClick={toggleEditForm} />
      )}
      {!renderEdit ? (
        <>
          <Data
            caption={t("info.labels.name")}
            value={currentRepository.name}
          />

          <div className="data-button-container">
            <Data
              caption={t("info.labels.url")}
              value={currentRepository.url}
            />
            <Button
              iconRight="pi pi-copy"
              onClick={() => handleCopy(currentRepository.url)}
              size="small"
              variant="textOnly"
            >
              {t("info.labels.copy")}
            </Button>
          </div>

          {currentRepository.credential?.username && (
            <Data
              caption={t("info.labels.username")}
              value={currentRepository.credential.username}
            />
          )}

          {currentRepository.credential?.accessToken && (
            <>
              <div className="data-button-container">
                <Data
                  caption={t("info.labels.accessToken")}
                  value={
                    showToken
                      ? currentRepository.credential.accessToken
                      : maskToken(currentRepository.credential.accessToken)
                  }
                />
                <Button
                  iconRight={showToken ? "pi pi-eye-slash" : "pi pi-eye"}
                  onClick={toggleShowToken}
                  size="small"
                  variant="textOnly"
                >
                  {t(showToken ? "info.labels.hide" : "info.labels.show")}
                </Button>
              </div>

              <Button
                className="btn-validate-credential"
                disabled={
                  !(
                    currentRepository.credential.username &&
                    currentRepository.credential.accessToken
                  )
                }
                label={t("form.actions.validateCredentials")}
                loading={isFetching}
                onClick={validateCredentialsHandler}
                severity="secondary"
              />
            </>
          )}
        </>
      ) : (
        <RepositoryForm
          handleEdit={handleEdit}
          onCancel={toggleEditForm}
          repository={currentRepository}
          setPageTitle={setPageTitle}
        />
      )}
    </div>
  ) : (
    <LoadingPage />
  );
};
