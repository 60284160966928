import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal } from "@dzangolab/react-ui";
import { BranchMatchingRule, ReleaseType, parseRegexPattern } from "core";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import BranchRuleFormFields from "./BranchRuleFormFields";
import {
  useCreateBranchRuleMutation,
  useDeleteBranchRuleMutation,
  useUpdateBranchRuleMutation,
} from "../../../../redux/apis/branch-rules";

interface Properties {
  actionLabel?: string;
  branchRule?: BranchMatchingRule;
  fieldErrors?: Record<string, string>;
  releaseTypes?: ReleaseType[];
  repositoryId: number;
  workspaceId: number;
  ruleTypes?: { id: number; name: string }[];
  onHide: () => void;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface SuccessResponse {
  data: BranchMatchingRule;
}

const BranchRuleForm: React.FC<Properties> = (properties) => {
  const {
    branchRule,
    fieldErrors,
    ruleTypes,
    releaseTypes,
    repositoryId,
    workspaceId,
    onHide,
  } = properties;
  const { t } = useTranslation("repositoryDetails");
  const [triggerUpdate, { isLoading: isSubmitting }] =
    useUpdateBranchRuleMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [triggerAdd] = useCreateBranchRuleMutation();
  const [deleteBranchRule] = useDeleteBranchRuleMutation();

  const handleSubmit = async (data: z.infer<typeof validationSchema>) => {
    const includePatterns =
      data.includePatterns && data.includePatterns.split(",");
    const excludePatterns =
      data.excludePatterns && data.excludePatterns.split(",");

    const input = {
      repositoryId,
      includePatterns: includePatterns !== "" ? includePatterns : null,
      excludePatterns: excludePatterns !== "" ? excludePatterns : null,
      ruleTypeId: parseInt(data.ruleTypeId), // [SR] remove it after RadioInput type fixes on skeleton
    };

    let response: SuccessResponse | ErrorResponse;

    try {
      if (!branchRule) {
        response = (await triggerAdd({
          workspaceId: workspaceId,
          input: {
            ...data,
            ...input,
            isDefault: false,
          },
        })) as SuccessResponse | ErrorResponse;
      } else {
        response = (await triggerUpdate({
          id: branchRule.id,
          workspaceId: workspaceId,
          input: {
            ...data,
            ...input,
          },
        })) as SuccessResponse | ErrorResponse;
      }

      if ("data" in response) {
        const successMessage = branchRule
          ? t("messages.success.update")
          : t("messages.success.create");

        toast.success(successMessage);

        onHide();
      } else if ("error" in response) {
        const errorMessage = branchRule
          ? t("messages.error.update")
          : t("messages.error.create");

        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(t("messages.error.default"));
    }
  };

  const defaultValues = {
    releaseTypeId: null,
    ruleTypeId: null,
    includePatterns: "",
    excludePatterns: "",
    isActive: true,
  };

  const getInitialValues = () => {
    if (!branchRule) return defaultValues;

    const includePatterns = branchRule.includePatterns
      ? branchRule.includePatterns.join(",")
      : "";

    const excludePatterns = branchRule.excludePatterns
      ? branchRule.excludePatterns.join(",")
      : "";

    return {
      releaseTypeId: branchRule.releaseTypeId,
      ruleTypeId: branchRule.ruleTypeId.toString(),
      includePatterns: includePatterns,
      excludePatterns: excludePatterns,
      isActive: branchRule.isActive || false,
    };
  };

  const validationSchema = z
    .object({
      releaseTypeId: z.number({
        required_error: t("form.validations.releaseTypeId.required"),
        invalid_type_error: t("form.validations.releaseTypeId.required"),
      }),
      ruleTypeId: z.string({
        required_error: t("form.validations.ruleTypeId.required"),
        invalid_type_error: t("form.validations.ruleTypeId.required"),
      }),
      includePatterns: z.string(),
      excludePatterns: z.string(),
      isActive: z.boolean(),
    })
    .superRefine((data, context) => {
      const regexRuleType = ruleTypes?.find(
        (rule) => rule.name === "regex",
      )?.id;

      if (parseInt(data.ruleTypeId) === regexRuleType) {
        if (!parseRegexPattern(data.includePatterns)) {
          context.addIssue({
            code: "custom",
            path: ["includePatterns"],
            message: t("form.validations.includePatterns.invalidRegex"),
          });
        }

        if (!parseRegexPattern(data.excludePatterns)) {
          context.addIssue({
            code: "custom",
            path: ["excludePatterns"],
            message: t("form.validations.excludePatterns.invalidRegex"),
          });
        }
      }
    });

  const onRuleDelete = async () => {
    if (!branchRule) return;

    setShowDeleteModal(false);

    await deleteBranchRule({
      workspaceId: workspaceId,
      repositoryId: repositoryId,
      branchMatchingId: branchRule.id,
    })
      .then(() => {
        toast.success(t("branchRule.delete.message.success"));

        onHide();
      })
      .catch(() => {
        toast.error(t("branchRule.delete.message.error"));
      });
  };

  return (
    <Provider
      defaultValues={getInitialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <BranchRuleFormFields
        actionLabel={branchRule && t("form.actions.edit")}
        fieldErrors={fieldErrors}
        handleCancel={onHide}
        handleDelete={() => setShowDeleteModal(true)}
        isLoading={isSubmitting ?? false}
        releaseTypes={releaseTypes}
        ruleTypes={ruleTypes}
      />

      <ConfirmationModal
        accept={onRuleDelete}
        acceptButtonOptions={{
          label: t("branchRule.delete.confirmation.buttonLabel.accept"),
          severity: "danger",
        }}
        cancelButtonOptions={{
          label: t("branchRule.delete.confirmation.buttonLabel.cancel"),
        }}
        header={t("branchRule.delete.confirmation.header")}
        message={t("branchRule.delete.confirmation.message")}
        onHide={() => setShowDeleteModal(false)}
        visible={showDeleteModal}
      />
    </Provider>
  );
};

export default BranchRuleForm;
