import { useTranslation } from "@dzangolab/react-i18n";
import { LoadingPage } from "@dzangolab/react-ui";
import {
  UserEnabledSidebarHeaderLayout,
  logout,
  useUser,
} from "@dzangolab/react-user";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ROUTES, SESSION_STORAGE_KEYS, USER_ROLES } from "../constants";
import { Header } from "./components";
import { useCurrentWorkspace } from "../hooks/UseCurrentWorkspace";
import { hasRole } from "../libs";
import { useLazyGetReleaseBySecondaryIdentifierQuery } from "../redux/apis/release";
import { useLazyGetWorkspaceBySecondaryIdentifierQuery } from "../redux/apis/workspaces";
import { WebSocketProvider } from "../WebSocketContext";
import { Footer } from "./components/Footer";
import OverlaySidebar from "./components/OverlaySidebar";
import baseApi from "../redux/apis/base-api";
import {
  resetSelectedWorkspaces,
  setSelectedWorkspace,
} from "../redux/SelectedWorkspacesSlice";

import type { NavGroupType, NavItemType } from "@dzangolab/react-ui";

export const WorkspaceLayout = (): JSX.Element => {
  const { t } = useTranslation("app");
  const parameters = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const sidebarReference = useRef<HTMLDivElement>(null);
  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const { workspaceSecondaryId, releaseSecondaryId } = parameters as {
    workspaceSecondaryId: string;
    releaseSecondaryId: string;
  };

  const [
    getWorkspaceBySecondaryIdentifier,
    { data: workspace, isFetching: isFetchingWorkspace, error: workspaceError },
  ] = useLazyGetWorkspaceBySecondaryIdentifierQuery();
  const [
    getReleaseBySecondaryIdentifier,
    { data: release, isFetching: isFetchingRelease },
  ] = useLazyGetReleaseBySecondaryIdentifierQuery();

  const { workspace: selectedWorkspace, release: selectedRelease } =
    useCurrentWorkspace();

  const routes = [
    {
      key: "header.menu.releases",
      route: ROUTES.WORKSPACE_RELEASES,
    },
    ...(isDeveloper
      ? [
          {
            key: "header.menu.glossary",
            route: ROUTES.WORKSPACE_GLOSSARY,
          },
        ]
      : []),
    {
      key: "header.menu.team",
      route: ROUTES.WORKSPACE_TEAM,
    },
    {
      key: "header.menu.settings.label",
      route: ROUTES.WORKSPACE_SETTINGS,
    },
  ];

  const signout = async () => {
    if (await logout()) {
      setUser(null);
      sessionStorage.removeItem(
        SESSION_STORAGE_KEYS.WORKSPACE_REDIRECTED_AFTER_LOGIN,
      );
      handleLogout(dispatch);
      toast.success(
        t("logout.message", {
          ns: "user",
        }),
      );
    }
  };

  const userMenu: NavItemType[] = [
    {
      icon: "pi pi-lock",
      label: t("user:changePassword.title"),
      onClick: () => navigate(ROUTES.CHANGE_PASSWORD),
    },
    {
      icon: "pi pi-user",
      label: t("user:userMenu.profile"),
      onClick: () => navigate(ROUTES.PROFILE),
    },
    {
      icon: "pi pi-power-off",
      label: t("header.menu.logout"),
      onClick: signout,
    },
  ];

  const headerMenu = generateHeaderNavigationMenu(
    t,
    routes,
    selectedWorkspace?.secondaryIdentifier,
    selectedRelease?.secondaryIdentifier,
  );

  const toggleSidebar = () => {
    if (!sidebarReference.current) {
      return;
    }
    sidebarReference.current.classList.toggle("open");
  };

  useEffect(() => {
    if (workspaceSecondaryId) {
      getWorkspaceBySecondaryIdentifier(workspaceSecondaryId);
    }
  }, [workspaceSecondaryId, getWorkspaceBySecondaryIdentifier]);

  useEffect(() => {
    if (
      !isFetchingWorkspace &&
      workspaceSecondaryId &&
      workspaceError &&
      "status" in workspaceError &&
      workspaceError.status === 404
    ) {
      navigate(ROUTES.WORKSPACES);
    }
  }, [workspaceSecondaryId, isFetchingWorkspace, workspaceError, navigate]);

  useEffect(() => {
    if (workspace && workspace.id && releaseSecondaryId) {
      getReleaseBySecondaryIdentifier({
        workspaceId: workspace.id,
        releaseSecondaryId,
      });
    }
  }, [workspace, releaseSecondaryId, getReleaseBySecondaryIdentifier]);

  useEffect(() => {
    if (workspace) {
      dispatch(setSelectedWorkspace({ workspace, release }));
    }
  }, [workspace, release, dispatch]);

  return (
    <WebSocketProvider>
      <UserEnabledSidebarHeaderLayout
        children={
          <>
            {isFetchingRelease || isFetchingWorkspace ? (
              <LoadingPage />
            ) : (
              <Outlet />
            )}
            <Footer />
          </>
        }
        className="workspaces-layout"
        customHeader={
          <Header
            navigationMenu={
              user ? { id: "navigation-menu", menu: headerMenu } : undefined
            }
            title={workspace?.name}
            toggleSidebar={toggleSidebar}
            userMenu={userMenu}
          />
        }
        customSidebar={<OverlaySidebar sidebarRef={sidebarReference} />}
        navigationMenu={user ? { id: "navigation-menu", menu: headerMenu } : []}
      />
    </WebSocketProvider>
  );
};

const generateHeaderNavigationMenu = (
  t: (key: string) => string,
  menuRoutes: {
    key: string;
    route: string;
  }[],
  workspaceSecondaryId: string,
  releaseSecondaryId: string,
): Array<NavItemType | NavGroupType> => {
  return menuRoutes.map((route) => ({
    ...route,
    route: route.route
      .replace(":workspaceSecondaryId", workspaceSecondaryId)
      .replace(":releaseSecondaryId", releaseSecondaryId),
    label: t(route.key),
  }));
};

const handleLogout = (dispatch: any) => {
  dispatch(resetSelectedWorkspaces());
  dispatch(baseApi.util.resetApiState());
};
