import { Button } from "@dzangolab/react-ui";
import { DirectoryTreeNode } from "core";
import { Column } from "primereact/column";
import { TreeNode } from "primereact/treenode";
import { TreeTable } from "primereact/treetable";
import { Link } from "react-router-dom";

interface Properties {
  currentWorkspaceSecondaryId: string;
  documentTree: DirectoryTreeNode[];
  isLoading: boolean;
  onAddFile: (destination?: string) => void;
  selectedReleaseSecondaryId: string;
}

const DocumentTree: React.FC<Properties> = (properties) => {
  const {
    documentTree,
    isLoading,
    currentWorkspaceSecondaryId,
    selectedReleaseSecondaryId,
    onAddFile,
  } = properties;

  const parseDocumentTree = (
    documentTree: DirectoryTreeNode[],
    parentNode?: DirectoryTreeNode,
    currentPath = "",
  ): TreeNode[] => {
    return documentTree?.map((document, index) => {
      let path = "";

      if (document.type === "file") {
        path = currentPath + "/" + document.name;
      } else if (document.type === "directory") {
        path = currentPath + "/" + document.name;
      }

      const getChildren = (
        document: DirectoryTreeNode,
      ): TreeNode[] | undefined => {
        if (document.type === "file") {
          return undefined;
        }

        if (document.type === "directory") {
          if (document.children && document.children.length > 0) {
            return parseDocumentTree(document.children, document, path);
          } else {
            return [];
          }
        }
      };

      return {
        key: parentNode
          ? `${parentNode.name}-${document.name}`
          : `${document.name}
        }-${index.toString()}`,
        data: { docs: document.name, path },
        children: getChildren(document),
      };
    });
  };

  const getAddFileButton = (destination?: string) => {
    return (
      <Button
        iconLeft={"pi pi-plus"}
        onClick={() => onAddFile(destination)}
        variant="textOnly"
      />
    );
  };

  return (
    <TreeTable
      className="docs-tree-table"
      loading={isLoading}
      value={parseDocumentTree(documentTree)}
    >
      <Column
        body={(node: TreeNode) => {
          const isFolder = !!node.children;

          if (isFolder) {
            return <span>{node.data.docs}</span>;
          }

          return (
            <Link
              to={`/workspaces/${currentWorkspaceSecondaryId}/releases/${selectedReleaseSecondaryId}/docs/${node.data.path}`}
            >
              {node.data.docs}
            </Link>
          );
        }}
        expander
        field="docs"
      />
      <Column
        align={"right"}
        body={(node: TreeNode) => {
          const isFolder = !!node.children;

          if (!isFolder) {
            return <></>;
          }

          return getAddFileButton(node.data.path);
        }}
        field="actions"
        style={{
          width: "100px",
        }}
      />
    </TreeTable>
  );
};

export default DocumentTree;
