import { Email, FormActions, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";

interface IProperties {
  isLoading: boolean;
  onCancel: () => void;
}

const InvitationFormFields: React.FC<IProperties> = ({
  isLoading,
  onCancel,
}) => {
  const { t } = useTranslation("workspaceTeam");
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Email
        label={t("invite-user.form.fields.email.label")}
        name="email"
        placeholder={t("invite-user.form.fields.email.placeholder")}
        required={true}
      />
      <FormActions
        actions={[
          {
            id: "cancel",
            label: t("invite-user.form.actions.cancel"),
            type: "button",
            onClick: onCancel,
          },
          {
            id: "submit",
            label: t("invite-user.form.actions.submit"),
            loading: isLoading,
            disabled: Object.keys(errors).length > 0,
          },
        ]}
      />
    </>
  );
};

export default InvitationFormFields;
