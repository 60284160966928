import { useTranslation } from "@dzangolab/react-i18n";
import {
  Page,
  TableColumnDefinition,
  TDataTable as DataTable,
  Tag,
} from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { useEffect } from "react";

import { InvitationModal } from "./components";
import { USER_ROLES } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../libs";
import { useLazyGetWorkspaceUsersQuery } from "../../redux/apis/teams";

const Team = () => {
  const { t } = useTranslation("workspaceTeam");

  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [
    getWorkspaceUsers,
    { data: workspaceUsers, isFetching: isFetchingWorkspaceUsers },
  ] = useLazyGetWorkspaceUsersQuery();

  useEffect(() => {
    if (currentWorkspace) {
      getWorkspaceUsers({ teamId: currentWorkspace.id });
    }
  }, [currentWorkspace, getWorkspaceUsers]);

  const columns: TableColumnDefinition<any>[] = [
    {
      accessorKey: "email",
      header: t("table.columns.email"),
      enableSorting: true,
    },
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      width: "25rem",
      minWidth: "25rem",
      maxWidth: "25rem",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          (original.givenName ? original.givenName : "") +
            (original.middleNames ? " " + original.middleNames : "") +
            (original.surname ? " " + original.surname : "") || "-"
        );
      },
    },
    {
      accessorKey: "roles",
      header: t("table.columns.role"),
      enableSorting: true,
      width: "10rem",
      minWidth: "10rem",
      maxWidth: "10rem",
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.isOwner ? <Tag label={t("owner")} /> : <></>;
      },
    },
  ];

  const getToolbar = () => {
    return (
      <>
        <InvitationModal
          onSubmitted={() => getWorkspaceUsers({ teamId: currentWorkspace.id })}
        />
      </>
    );
  };

  return (
    <Page
      loading={isFetchingWorkspaceUsers}
      title={t("title")}
      toolbar={isDeveloper && getToolbar()}
    >
      {workspaceUsers && (
        <DataTable
          className="workspace-users-table"
          columns={columns}
          data={workspaceUsers}
          emptyTableMessage={t("table.empty")}
          isLoading={isFetchingWorkspaceUsers}
        />
      )}
    </Page>
  );
};

export default Team;
