interface Properties {
  onLetterClick: (letter: string) => void;
  entriesCount: { [key: string]: number };
  glossaryKeys?: string[];
}

const AlphabetNavbar: React.FC<Properties> = (properties) => {
  const { onLetterClick, entriesCount, glossaryKeys } = properties;

  return (
    <div className="alphabet-navbar">
      {glossaryKeys &&
        glossaryKeys.map((letter) => (
          <button
            className={
              entriesCount[letter.toLocaleUpperCase()] ? "" : "disabled"
            }
            key={letter}
            onClick={() => onLetterClick(letter)}
          >
            {letter.toUpperCase()}
          </button>
        ))}
    </div>
  );
};

export default AlphabetNavbar;
