import { ServiceFixtures } from "core";

import baseApi, { TagsEnum } from "./base-api";

const fixturesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFixtures: build.query<ServiceFixtures[], { workspaceId: number }>({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/fixtures`,
          method: "GET",
        };
      },
      providesTags: [TagsEnum.Fixtures],
    }),

    uploadFixtures: build.mutation<
      { success?: string; error?: string },
      { input: FormData; workspaceId: number }
    >({
      query: ({ input, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/fixtures`,
          method: "POST",
          body: input,
        };
      },
      invalidatesTags: [TagsEnum.Fixtures],
    }),
  }),
});

export const { useLazyGetFixturesQuery, useUploadFixturesMutation } =
  fixturesApi;

export default fixturesApi;
