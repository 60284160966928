import { useTranslation } from "@dzangolab/react-i18n";
import {
  TDataTable as DataTable,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { formatDate, Service, Workspace } from "core";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getReleaseStatusLabel } from "../../../../libs/release";
import { useLazyGetAttachedReleasesQuery } from "../../../../redux/apis/services";
import Loading from "../../../Loading";

interface Properties {
  workspace: Workspace;
  service: Service;
}

const AttachedReleases: React.FC<Properties> = (properties) => {
  const { workspace, service } = properties;
  const [fetchAttachedReleases, { data: attachedReleases, isFetching }] =
    useLazyGetAttachedReleasesQuery();
  const { t } = useTranslation("release");
  const navigate = useNavigate();

  useEffect(() => {
    if (workspace && service) {
      fetchAttachedReleases({
        workspaceId: workspace.id,
        serviceSecondaryIdentifier: service.secondaryIdentifier,
      });
    }
  }, [fetchAttachedReleases, service, workspace]);

  const selectRelease = (release: any) => {
    if (release && workspace) {
      navigate(
        `/workspaces/${workspace.secondaryIdentifier}/releases/${release.secondaryIdentifier}`,
      );
    }
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Link
            className="release-name-link"
            onClick={(event_) => {
              event_.preventDefault();
              selectRelease(original);
            }}
            to={`/workspaces/${workspace.secondaryIdentifier}/releases/${original.secondaryIdentifier}`}
          >
            {original.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAType = rowA.original.type?.name || "";
        const rowBType = rowB.original.type?.name || "";

        return rowAType.localeCompare(rowBType);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.type?.name ?? "";
      },
    },
    {
      accessorKey: "state",
      accessorFn: (row) => row.state ?? undefined,
      header: t("table.columns.state"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAState = rowA.original.state?.i18n[0].name || "";
        const rowBState = rowB.original.state?.i18n[0].name || "";

        return rowAState.localeCompare(rowBState);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.state?.i18n[0].name ?? "";
      },
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.createdAt"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return formatDate(original.createdAt);
      },
    },
    {
      accessorKey: "status",
      header: t("table.columns.status"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAStatus = getReleaseStatusLabel(rowA.original, t) || "";
        const rowBStatus = getReleaseStatusLabel(rowB.original, t) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const status = getReleaseStatusLabel(original, t);

        return (
          <div className="status">
            <div className="status-text">{status}</div>
          </div>
        );
      },
    },
  ];

  if (isFetching) {
    return <Loading />;
  }

  return (
    <div className="attached-releases">
      <h2>{t("releases")}</h2>

      <DataTable
        className="releases-table"
        columns={columns}
        data={attachedReleases || []}
        emptyTableMessage={t("table.empty")}
        isLoading={isFetching}
        manualSorting={false}
      />
    </div>
  );
};

export default AttachedReleases;
