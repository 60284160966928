import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Modal } from "@dzangolab/react-ui";
import { DocumentType } from "core";
import { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import AddDocumentFormFields from "./AddDocumentFormFields";
import { useLocalStorage } from "../../../../hooks";
import { useCreateDocumentMutation } from "../../../../redux/apis/document";

interface Properties {
  documentTypes: DocumentType[];
  workspaceId: number;
  onHide: () => void;
  visible: boolean;
  onDocumentAdded: () => void;
  targetDestination: string;
}

const AddDocumentDialog: React.FC<Properties> = (properties) => {
  const {
    documentTypes,
    onHide,
    visible,
    workspaceId,
    onDocumentAdded,
    targetDestination,
  } = properties;
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>();
  const [documentTypeToCreate, setDocumentTypeToCreate] = useState<
    "file" | "folder" | null
  >(null);

  const [createDocument, { isLoading: isCreatingDocument }] =
    useCreateDocumentMutation();
  const [selectedRelease] = useLocalStorage<any>("selectedRelease", null);

  const { t } = useTranslation("docs");

  const onSubmit = async (data: any) => {
    if (!selectedRelease) {
      return;
    }

    let response = (await createDocument({
      data: {
        ...data,
        destination: targetDestination,
        documentTypeToCreate,
      },
      workspaceId,
      releaseId: selectedRelease.id,
    })) as any;

    if ("error" in response) {
      if (
        response.error.status === 409 &&
        response.error.data.error === "DOCUMENT_ALREADY_EXISTS"
      ) {
        setFieldErrors({
          name: t("add-docs.message.error.docs-already-exists"),
        });

        return;
      }

      toast.error(t("add-docs.message.error.default"));
    } else {
      toast.success(t("add-docs.message.success.docs-added"));

      onHide();
      onDocumentAdded();
    }
  };

  const formValidationSchema = z.object({
    name: z.string().min(1),
    typeId: z.number().optional(),
  });

  const onDialogClose = () => {
    setDocumentTypeToCreate(null);
    setFieldErrors({});
    onHide();
  };

  return (
    <Modal
      className="add-document-dialog"
      header={t("add-docs.title")}
      onHide={onDialogClose}
      visible={visible}
    >
      {!documentTypeToCreate ? (
        <div className="add-document-question">
          <h4>{t("add-docs.document-types.question")}</h4>
          <Button
            label={t("add-docs.document-types.options.folder")}
            onClick={() => setDocumentTypeToCreate("folder")}
            variant="outlined"
          />
          <Button
            label={t("add-docs.document-types.options.document")}
            onClick={() => setDocumentTypeToCreate("file")}
            variant="outlined"
          />
        </div>
      ) : (
        <Provider onSubmit={onSubmit} validationSchema={formValidationSchema}>
          <AddDocumentFormFields
            documentTypes={documentTypes}
            documentTypeToCreate={documentTypeToCreate}
            fieldErrors={fieldErrors}
            submittingForm={isCreatingDocument}
          />
        </Provider>
      )}
    </Modal>
  );
};

export default AddDocumentDialog;
