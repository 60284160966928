import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { SupportedLocales } from "core";
import { z } from "zod";

import SelectLocaleFormFields from "./SelectLocaleFormFields";

interface Properties {
  onHide: () => void;
  visible: boolean;
  supportedLocales: SupportedLocales[];
  onLocaleSelected: (locale: string, mainLocale: boolean) => void;
}

const SelectLocaleDialog: React.FC<Properties> = (properties) => {
  const { onHide, visible, supportedLocales, onLocaleSelected } = properties;
  const { t } = useTranslation("glossary");

  const formValidationSchema = z.object({
    locale: z.string().nonempty(),
    mainLocale: z.boolean(),
  });

  const onSubmit = (data: any) => {
    onLocaleSelected(data.locale, data.mainLocale);
    onHide();
  };

  return (
    <Modal
      className="glossary-locale-dialog"
      header={t("glossary-settings.form.fields.locale.placeholder")}
      onHide={onHide}
      visible={visible}
    >
      <Provider onSubmit={onSubmit} validationSchema={formValidationSchema}>
        <SelectLocaleFormFields supportedLocales={supportedLocales} />
      </Provider>
    </Modal>
  );
};

export default SelectLocaleDialog;
