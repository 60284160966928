import { Page } from "@dzangolab/react-ui";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../../components/BackButton";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { ReleaseModuleTabView, Toolbar } from "../Overview/components";
import ReleaseModuleTitle from "./components/ReleaseModuleTitle";

const ReleaseAppView = () => {
  const parameters = useParams();
  const { release: currentRelease, workspace: currentWorkspace } =
    useCurrentWorkspace();

  const [releaseApp, setReleaseApp] = useState<ReleaseApp>();

  const { appSlug } = parameters;

  useEffect(() => {
    if (currentWorkspace && currentRelease) {
      const releaseApp = currentRelease.apps.find(
        (app: ReleaseApp) => app.slug === appSlug,
      );

      if (releaseApp) {
        setReleaseApp(releaseApp);
      }
    }
  }, [currentRelease, appSlug, currentWorkspace]);

  return (
    <Page
      breadcrumb={<BackButton />}
      className="release-app-page"
      title={
        releaseApp && currentRelease ? (
          <ReleaseModuleTitle module={releaseApp} release={currentRelease} />
        ) : (
          <></>
        )
      }
      toolbar={<Toolbar isDraft={currentRelease && currentRelease.isDraft} />}
    >
      {releaseApp && (
        <ReleaseModuleTabView
          key={releaseApp?.slug}
          module={releaseApp as ReleaseApp}
          release={currentRelease}
        />
      )}
    </Page>
  );
};

export default ReleaseAppView;
