import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";

import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";

const DataModel = () => {
  const { t } = useTranslation("workspaceDataModel");
  const { release } = useCurrentWorkspace();

  return (
    <Page
      title={
        <ReleasePageTitle subtitle={release?.name || ""} title={t("title")} />
      }
    ></Page>
  );
};

export default DataModel;
