import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  Page,
  TableColumnDefinition,
} from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import {
  Release,
  ReleaseApp,
  WORKSPACE_RELEASE_STATUS,
  formatDate,
} from "core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReleaseDuplicateModal, ReleaseFormModal } from "./components";
import { RELEASE_STATUS_CLOSED, USER_ROLES } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../libs";
import { getReleaseStatusLabel } from "../../libs/release";
import {
  useDeleteReleaseMutation,
  useLazyDeployReleaseQuery,
  useLazyGetReleaseTypesQuery,
  useLazyGetWorkspaceReleasesQuery,
  useLazySetupAndBuildReleaseQuery,
  useLazyStopReleaseQuery,
  useMarkReleaseAsReadyMutation,
} from "../../redux/apis/release";
import { resetSelectedRelease } from "../../redux/SelectedWorkspacesSlice";

const WorkspaceReleases = () => {
  const { t } = useTranslation("release");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    workspace: currentWorkspace,
    release,
    getReleaseActionStates,
  } = useCurrentWorkspace();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [releaseToDuplicate, setReleaseToDuplicate] = useState<any>();
  const [loadingReleases, setLoadingReleases] = useState<number[]>([]);
  const [statusFilter, setStatusFilter] = useState({ closed: false });

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [markReleaseAsReady] = useMarkReleaseAsReadyMutation();
  const [fetchReleases, { data: releases, isFetching: isLoadingReleases }] =
    useLazyGetWorkspaceReleasesQuery();
  const [setupBuildRelease] = useLazySetupAndBuildReleaseQuery();
  const [fetchReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [deployRelease] = useLazyDeployReleaseQuery();
  const [stopRelease] = useLazyStopReleaseQuery();

  const [deleteRelease] = useDeleteReleaseMutation();

  const handleFetchReleases = useCallback(() => {
    if (currentWorkspace) {
      const formattedFilters = statusFilter.closed
        ? {
            key: "status",
            operator: "eq",
            value: RELEASE_STATUS_CLOSED,
          }
        : undefined;

      fetchReleases({
        workspaceId: currentWorkspace.id,
        filters: formattedFilters,
      });
    }
  }, [currentWorkspace, fetchReleases, statusFilter]);

  useEffect(() => {
    if (currentWorkspace) {
      fetchReleaseTypes({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseTypes]);

  const selectRelease = (release: any) => {
    if (release && currentWorkspace) {
      navigate(
        `/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${release.secondaryIdentifier}`,
      );
    }
  };

  const setupAndBuildActionHandler = async (selectedRelease: any) => {
    if (currentWorkspace && selectedRelease) {
      const response = await setupBuildRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }
    }
  };

  const deleteReleaseActionHandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      const response = await deleteRelease(selectedRelease.id);

      if (response.data) {
        toast.success(t("table.delete.message.success"));

        if (release && release.id === selectedRelease.id) {
          dispatch(resetSelectedRelease({ workspace: currentWorkspace }));
        }

        fetchReleases({ workspaceId: currentWorkspace.id });
      } else if (response.error) {
        toast.error(t("table.delete.message.error"));
      }
    }
  };

  const onReleaseMarkedAsReady = async (releaseId: number) => {
    if (currentWorkspace) {
      const response = await markReleaseAsReady({
        releaseId,
        workspaceId: currentWorkspace.id,
      });

      if (response.error) {
        toast.error(t("mark-as-ready.message.error"));

        return;
      }

      fetchReleases({ workspaceId: currentWorkspace.id });

      toast.success(t("mark-as-ready.message.success"));
    }
  };

  const renderToolbar = () => {
    return (
      <Button onClick={openModal}>
        <i className="pi pi-plus"></i>
      </Button>
    );
  };

  const playActionHandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      setLoadingReleases((previous) => [...previous, selectedRelease.id]);
      const response = await deployRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      setLoadingReleases((previous) =>
        previous.filter((id) => id !== selectedRelease.id),
      );

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }
    }
  };

  const stopActionHandler = async (selectedRelease: Release) => {
    if (currentWorkspace && selectedRelease) {
      setLoadingReleases((previous) => [...previous, selectedRelease.id]);

      const response = await stopRelease({
        workspaceId: currentWorkspace?.id,
        releaseId: selectedRelease.id,
      });

      setLoadingReleases((previous) =>
        previous.filter((id) => id !== selectedRelease.id),
      );

      if (response.error) {
        toast.error(t("messages.error.default"));

        return;
      }

      toast.info(t("messages.info.stopping-release"));
    }
  };

  const ShowClosedReleasesCheckbox = () => {
    return (
      <div className="checkbox-wrapper checkbox-filters">
        <input
          checked={statusFilter.closed}
          id="statusFilter"
          onChange={(event) => {
            setStatusFilter({ ...statusFilter, closed: event.target.checked });
          }}
          type="checkbox"
        />
        <label htmlFor="statusFilter">
          {t("table.filters.status.checkbox-label")}
        </label>
      </div>
    );
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "name",
      header: t("table.columns.name"),
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return (
          <Link
            className="release-name-link"
            onClick={(event_) => {
              event_.preventDefault();
              selectRelease(original);
            }}
            to={`/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${original.secondaryIdentifier}`}
          >
            {original.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "type",
      header: t("table.columns.type"),
      width: "10rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAType = rowA.original.type?.name || "";
        const rowBType = rowB.original.type?.name || "";

        return rowAType.localeCompare(rowBType);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.type?.name ?? "";
      },
    },
    {
      accessorKey: "state",
      accessorFn: (row) => row.state ?? undefined,
      header: t("table.columns.state"),
      width: "10rem",
      enableSorting: true,
      sortUndefined: "last",
      sortingFn: (rowA, rowB) => {
        const rowAState = rowA.original.state?.i18n[0].name || "";
        const rowBState = rowB.original.state?.i18n[0].name || "";

        return rowAState.localeCompare(rowBState);
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return original.state?.i18n[0].name ?? "";
      },
    },
    {
      accessorKey: "createdAt",
      header: t("table.columns.createdAt"),
      width: "10rem",
      enableSorting: true,
      cell: ({ row: { original } }: { row: { original: any } }) => {
        return formatDate(original.createdAt);
      },
    },
    {
      accessorKey: "status",
      header: t("table.columns.status"),
      width: "14rem",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const rowAStatus = getReleaseStatusLabel(rowA.original, t) || "";
        const rowBStatus = getReleaseStatusLabel(rowB.original, t) || "";

        return rowAStatus.localeCompare(rowBStatus);
      },
      meta: {
        serverFilterFn: "equals",
      },
      cell: ({ row: { original } }: { row: { original: any } }) => {
        const { disablePlay, disableStop, isProcessing, isStarting } =
          getReleaseActionStates(original);
        const status = getReleaseStatusLabel(original, t);

        return (
          <div className="status">
            <div className="status-text">{status}</div>
            {!(disablePlay && disableStop) && !original.closedAt && (
              <>
                {disablePlay ? (
                  <Button
                    disabled={
                      loadingReleases.includes(original.id) ||
                      original.isDraft ||
                      isProcessing ||
                      isStarting ||
                      disableStop
                    }
                    iconLeft="pi pi-stop-circle"
                    onClick={() => {
                      stopActionHandler(original);
                    }}
                    rounded
                    severity="danger"
                    variant="textOnly"
                  />
                ) : (
                  <Button
                    disabled={
                      loadingReleases.includes(original.id) ||
                      original.isDraft ||
                      isProcessing ||
                      isStarting ||
                      disablePlay
                    }
                    iconLeft="pi pi-play-circle"
                    onClick={() => {
                      playActionHandler(original);
                    }}
                    rounded
                    severity="success"
                    variant="textOnly"
                  />
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Page title={t("releases")} toolbar={isDeveloper && renderToolbar()}>
      <ShowClosedReleasesCheckbox />
      <DataTable
        className="releases-table"
        columns={columns}
        data={releases || []}
        dataActionsMenu={(data) => {
          return {
            actions: [
              {
                label: t("table.actions.view"),
                onClick: (rowData: any) => {
                  selectRelease(rowData);
                },
              },
              {
                display: isDeveloper,
                label: t("table.actions.duplicate"),
                onClick: (rowData: any) => {
                  setReleaseToDuplicate(rowData);
                },
              },
              {
                confirmationOptions: {
                  message: t("table.mark-as-ready.message", {
                    releaseName: data.name,
                  }),
                  header: t("table.mark-as-ready.header"),
                  acceptButtonOptions: {
                    label: t("table.mark-as-ready.actions.mark-as-ready"),
                  },
                  cancelButtonOptions: {
                    label: t("table.mark-as-ready.actions.cancel"),
                  },
                },
                display: (data) => {
                  return isDeveloper && data.isDraft;
                },
                label: t("table.actions.mark-as-ready"),
                onClick: (rowData: any) => {
                  onReleaseMarkedAsReady(rowData.id);
                },
                requireConfirmationModal: true,
              },
              {
                className: "table-action-warning",
                confirmationOptions: {
                  message: t("table.force-setup.message", {
                    releaseName: data.name,
                  }),
                  header: t("table.force-setup.header", {
                    releaseName: data.name,
                  }),
                  acceptButtonOptions: {
                    label: t("table.force-setup.actions.force-setup"),
                  },
                  cancelButtonOptions: {
                    label: t("table.force-setup.actions.cancel"),
                  },
                },
                display: (data) => {
                  return isDeveloper && !data.isDraft;
                },
                disabled: ({ apps, closedAt }) => {
                  if (!currentWorkspace.isCodeGenerated || closedAt)
                    return true;

                  return apps.every(
                    (app: ReleaseApp) =>
                      app.status === WORKSPACE_RELEASE_STATUS.BUILDING,
                  );
                },
                label: t("table.actions.force-setup"),
                requireConfirmationModal: true,
                onClick: (rowData: any) => {
                  setupAndBuildActionHandler(rowData);
                },
              },
              {
                className: "table-action-danger",
                confirmationOptions: {
                  message: t("table.delete.message.confirm", {
                    release: data.name,
                  }),
                  header: t("table.delete.header"),
                  acceptButtonOptions: {
                    label: t("table.delete.actions.delete"),
                    className: "danger",
                  },
                  cancelButtonOptions: {
                    label: t("table.delete.actions.cancel"),
                  },
                },
                display: isDeveloper,
                label: t("table.actions.delete"),
                requireConfirmationModal: true,
                onClick: (rowData: Release) => {
                  deleteReleaseActionHandler(rowData);
                },
              },
            ],
          };
        }}
        emptyTableMessage={t("table.empty")}
        fetchData={handleFetchReleases}
        isLoading={isLoadingReleases}
        manualSorting={false}
      />
      <ReleaseFormModal
        onHide={closeModal}
        releaseTypes={releaseTypes}
        visible={isModalVisible}
      />
      <ReleaseDuplicateModal
        onDuplicate={(secondaryIdentifier: string) => {
          navigate(
            `/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${secondaryIdentifier}`,
          );
        }}
        onHide={() => {
          setReleaseToDuplicate(null);
        }}
        release={releaseToDuplicate}
        releaseTypes={releaseTypes}
        visible={!!releaseToDuplicate}
      />
    </Page>
  );
};

export default WorkspaceReleases;
