import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  TDataTable as DataTable,
  TableColumnDefinition,
  Tag,
} from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { BranchMatchingRule, ReleaseType, Workspace } from "core";
import React, { useState } from "react";

import { USER_ROLES } from "../../../../../constants";
import { hasRole } from "../../../../../libs";
import BranchRuleFormModal from "../../../components/BranchRuleFormModal";

interface Properties {
  branchRules?: BranchMatchingRule[];
  isLoading: boolean;
  releaseTypes?: ReleaseType[];
  repositoryId: number;
  ruleTypes?: { id: number; name: string }[];
  workspace: Workspace;
}

const BranchRules: React.FC<Properties> = ({
  branchRules,
  isLoading,
  releaseTypes,
  repositoryId,
  ruleTypes,
  workspace,
}) => {
  const { t } = useTranslation("repositoryDetails");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBranchRule, setSelectedBranchRule] =
    useState<BranchMatchingRule>();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedBranchRule(undefined);
  };

  const editActionHandler = async (branchRule: BranchMatchingRule) => {
    setSelectedBranchRule(branchRule);
    openModal();
  };

  const columns: Array<TableColumnDefinition<any>> = [
    {
      accessorKey: "includePatterns",
      enableSorting: true,
      header: t("table.columns.includePatterns"),
    },
    {
      accessorKey: "ruleTypeName",
      enableSorting: true,
      header: t("table.columns.ruleType"),
      minWidth: "8rem",
      maxWidth: "8rem",
      width: "8rem",
    },
    {
      accessorKey: "releaseTypeName",
      enableSorting: true,
      header: t("table.columns.releaseType"),
      minWidth: "10rem",
      maxWidth: "10rem",
      width: "10rem",
    },
    {
      accessorKey: "excludePatterns",
      enableSorting: true,
      header: t("table.columns.excludePatterns"),
      minWidth: "15rem",
      maxWidth: "15rem",
      width: "15rem",
    },
    {
      accessorKey: "isActive",
      header: t("table.columns.active"),
      minWidth: "5rem",
      maxWidth: "5rem",
      width: "5rem",
      cell: ({
        row: { original },
      }: {
        row: { original: BranchMatchingRule };
      }) => {
        const isActive = original.isActive;

        return (
          <Tag
            color={isActive ? "green" : "red"}
            label={isActive ? t("table.tag.yes") : t("table.tag.no")}
          />
        );
      },
    },
  ];

  return (
    <>
      {isDeveloper && (
        <Button
          className="branch-rule-add-btn"
          iconLeft="pi pi-plus"
          onClick={() => openModal()}
          variant="filled"
        />
      )}
      <DataTable
        className="repository-rules-table"
        columns={columns}
        data={branchRules || []}
        dataActionsMenu={
          isDeveloper
            ? {
                actions: [
                  {
                    label: t("table.actions.edit"),
                    icon: "pi pi-pen-to-square",
                    onClick: (rowData) => editActionHandler(rowData),
                  },
                ],
              }
            : undefined
        }
        initialSorting={[{ id: "releaseTypeName", desc: false }]}
        isLoading={isLoading}
      />

      {releaseTypes && ruleTypes && (
        <BranchRuleFormModal
          branchRule={selectedBranchRule}
          onHide={closeModal}
          releaseTypes={releaseTypes}
          repositoryId={repositoryId}
          ruleTypes={ruleTypes}
          visible={isModalVisible}
          workspaceId={workspace.id}
        />
      )}
    </>
  );
};

export default BranchRules;
