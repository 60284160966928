import { useTranslation } from "@dzangolab/react-i18n";
import { Modal, Page } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { ReleaseApp } from "core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Toolbar } from "./components";
import AddReleaseApp from "./components/AddReleaseApp";
import LinkReleaseServiceDialog from "./components/LinkReleaseServiceDialog";
import ReleasePageTitle from "../../components/common/ReleasePageTitle";
import {
  RELEASE_SECTIONS,
  RELEASE_STATE_APPROVED,
  ROUTES,
  USER_ROLES,
} from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../libs";
import ReleaseGraph from "./components/ReleaseGraph";
import { useLazyGetReleaseStatesQuery } from "../../redux/apis/release";
import { useLazyGetServicesQuery } from "../../redux/apis/services";

const Overview: React.FC = () => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [addReleaseServiceDialogVisible, setAddReleaseServiceDialogVisible] =
    useState(false);
  const [addReleaseAppDialogVisible, setAddReleaseAppDialogVisible] =
    useState(false);
  const [moduleType, setModuleType] = useState("");

  const [getServices, { data: workspaceServices, isLoading: loadingServices }] =
    useLazyGetServicesQuery();
  const [fetchReleaseStates, { data: releaseStates }] =
    useLazyGetReleaseStatesQuery();

  const { release: currentRelease, workspace: currentWorkspace } =
    useCurrentWorkspace();

  const approvedStateId = releaseStates?.find(
    ({ slug }) => slug === RELEASE_STATE_APPROVED,
  )?.id;

  const isApproved =
    approvedStateId !== undefined &&
    approvedStateId === currentRelease?.stateId;

  useEffect(() => {
    fetchReleaseStates();

    if (currentWorkspace) {
      getServices({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleaseStates, getServices]);

  const addReleaseModuleHandler = (type: string, app?: ReleaseApp) => {
    if (app) {
      setModuleType("");
    } else {
      setModuleType(type);
    }

    if (type === RELEASE_SECTIONS.SERVICES) {
      setAddReleaseServiceDialogVisible(true);
    } else {
      setAddReleaseAppDialogVisible(true);
    }
  };

  const hideAddReleaseAppDialog = () => {
    setAddReleaseAppDialogVisible(false);
  };

  return (
    <Page
      className={"overview-page graph"}
      loading={
        loadingServices ||
        (currentWorkspace?.isCodeGenerated ? !currentRelease : false)
      }
      title={
        <ReleasePageTitle isApproved={isApproved} isEditable={isDeveloper} />
      }
      toolbar={<Toolbar isDraft={currentRelease && currentRelease.isDraft} />}
    >
      <ReleaseGraph
        onAddReleaseModule={(type, app) => {
          addReleaseModuleHandler(type, app);
        }}
        onReleaseModuleSettingClicked={(type, app) => {
          if (
            (type === RELEASE_SECTIONS.APIS ||
              type === RELEASE_SECTIONS.APPS) &&
            currentWorkspace &&
            currentRelease
          ) {
            navigate(
              ROUTES.RELEASE_APP.replace(
                ":workspaceSecondaryId",
                currentWorkspace.secondaryIdentifier,
              )
                .replace(
                  ":releaseSecondaryId",
                  currentRelease.secondaryIdentifier,
                )
                .replace(":appSlug", app.slug),
            );
          }
        }}
      />

      <LinkReleaseServiceDialog
        onHide={() => setAddReleaseServiceDialogVisible(false)}
        release={currentRelease}
        visible={addReleaseServiceDialogVisible}
        workspaceServices={workspaceServices ?? []}
      />

      {moduleType !== "" && (
        <Modal
          className="add-release-app-dialog"
          header={`${t("release-app.add.title", {
            appModule: moduleType.slice(0, -1),
            ns: "appOverview",
          })}`}
          onHide={hideAddReleaseAppDialog}
          visible={addReleaseAppDialogVisible}
        >
          <AddReleaseApp
            moduleType={moduleType}
            onHide={hideAddReleaseAppDialog}
          />
        </Modal>
      )}
    </Page>
  );
};

export default Overview;
