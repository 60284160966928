import { useTranslation } from "@dzangolab/react-i18n";
import { Page, TabView } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GeneralSettings from "./GeneralSettings";
import GlossarySettings from "./GlossarySettings";
import ReleaseType from "./ReleaseType";
import Repositories from "./Repositories";
import Services from "./Services";
import { USER_ROLES } from "../../constants";
import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../libs";

const URL_HASH_MAP: { [key: string]: string[] } = {
  general: ["general", "advanced"],
  glossary: ["glossary"],
  releaseTypes: ["releaseTypes"],
  repositories: ["repositories"],
  services: ["services"],
};

const Settings = () => {
  const { t } = useTranslation("settings");
  const { workspace } = useCurrentWorkspace();
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("general");
  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.replace("#", "");
      const tab = Object.keys(URL_HASH_MAP).find((key) =>
        URL_HASH_MAP[key].includes(hash),
      );

      if (tab === "glossary" && !isDeveloper) {
        setActiveTab("general");
      } else {
        setActiveTab(tab || "general");
      }
    }
  }, [isDeveloper, location]);

  const tabs = [
    "general",
    "glossary",
    "releaseTypes",
    "repositories",
    "services",
  ];

  const allTabs = [
    {
      label: t("tabs.general"),
      key: "general",
      children: <GeneralSettings />,
      closable: false,
    },
    ...(isDeveloper
      ? [
          {
            label: t("tabs.glossary"),
            key: "glossary",
            children: <GlossarySettings />,
            closable: false,
          },
        ]
      : []),
    {
      label: t("tabs.repositories"),
      key: "repositories",
      children: <Repositories />,
      closable: false,
    },
    ...(isDeveloper
      ? [
          {
            label: t("tabs.releaseType"),
            key: "releaseTypes",
            children: <ReleaseType />,
            closable: false,
          },
        ]
      : []),
    {
      label: t("tabs.services"),
      key: "services",
      children: <Services />,
      closable: false,
    },
  ];

  const onActiveTabChange = (key: string) => {
    if (key === activeTab) return;

    setActiveTab(key);
    navigate(`#${URL_HASH_MAP[key][0]}`, { replace: true });
  };

  return (
    <Page className="settings-page" title={t("title")}>
      <TabView
        activeKey={activeTab}
        id={`settings-${workspace}`}
        onActiveTabChange={onActiveTabChange}
        persistState={true}
        tabs={allTabs}
        visibleTabs={tabs}
      />
    </Page>
  );
};

export default Settings;
