import { useTranslation } from "@dzangolab/react-i18n";
import { Logo } from "@dzangolab/react-layout";
import { Workspace } from "core";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useLazyGetWorkspaceReleasesQuery } from "../../../redux/apis/release";
import { useLazyGetWorkspacesQuery } from "../../../redux/apis/workspaces";
import { resetSelectedWorkspaces } from "../../../redux/SelectedWorkspacesSlice";
import Loading from "../../../views/Loading";

interface IProperties {
  sidebarRef: React.RefObject<HTMLDivElement>;
}

const RELEASE_LIMIT_TO_DISPLAY = 10;

const OverlaySidebar: React.FC<IProperties> = (properties) => {
  const { sidebarRef } = properties;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const { t } = useTranslation("app");

  const [
    fetchWorkspaces,
    { data: workspaces, isFetching: isLoadingWorkspace },
  ] = useLazyGetWorkspacesQuery();

  const [fetchReleases, { data: releases }] =
    useLazyGetWorkspaceReleasesQuery();

  const toggleSidebar = () => {
    if (!sidebarRef.current) {
      return;
    }

    sidebarRef.current.classList.toggle("open");
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        sidebarRef.current.classList.remove("open");
      }
    },
    [sidebarRef],
  );

  const sortAndLimitReleases = (releases: any[]) => {
    if (!releases) {
      return [];
    }

    return releases.slice(0, RELEASE_LIMIT_TO_DISPLAY);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  useEffect(() => {
    if (currentWorkspace) {
      fetchReleases({ workspaceId: currentWorkspace.id });
    }
  }, [currentWorkspace, fetchReleases]);

  const handleWorkspaceClick = (workspace: Workspace) => {
    if (sidebarRef.current) {
      sidebarRef.current.classList.remove("open");
    }

    dispatch(resetSelectedWorkspaces());
    navigate(`/workspaces/${workspace.secondaryIdentifier}/releases`);
  };

  return (
    <div className="overlay-sidebar" id={"overlay-sidebar"} ref={sidebarRef}>
      <div className="header">
        <Logo />
        <span className="dz-toggle-sidebar" onClick={toggleSidebar}>
          <i className={"pi pi-times"}></i>
        </span>
      </div>

      {currentWorkspace && releases && releases.length > 0 && (
        <nav className="releases">
          <ul>
            <li>
              <Link
                onClick={() => toggleSidebar()}
                to={`/workspaces/${currentWorkspace.secondaryIdentifier}/releases`}
              >
                {t("sidebar.sections.releases")}
              </Link>
            </li>
            {sortAndLimitReleases(releases).map((release) => {
              return (
                <li key={`release-${release.secondaryIdentifier}`}>
                  <Link
                    onClick={toggleSidebar}
                    to={`/workspaces/${currentWorkspace.secondaryIdentifier}/releases/${release.secondaryIdentifier}`}
                  >
                    {release.name}
                  </Link>
                </li>
              );
            })}
            {releases.length > RELEASE_LIMIT_TO_DISPLAY && (
              <li>
                <Link
                  onClick={toggleSidebar}
                  to={`/workspaces/${currentWorkspace.secondaryIdentifier}/releases`}
                >
                  {t("sidebar.sections.actions.seeMore")}
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}

      {isLoadingWorkspace ? (
        <Loading />
      ) : (
        <nav className="workspaces">
          <ul>
            <li>
              <Link onClick={() => toggleSidebar()} to={`/workspaces`}>
                {t("sidebar.sections.workspaces")}
              </Link>
            </li>
            {workspaces?.map((workspace) => {
              return (
                <li
                  className={`${
                    currentWorkspace?.id === workspace.id ? "active" : ""
                  }`}
                  key={workspace.id}
                  onClick={() => handleWorkspaceClick(workspace)}
                >
                  {workspace.name}
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default OverlaySidebar;
