import { useTranslation } from "@dzangolab/react-i18n";
import {
  Header as DzangoHeader,
  HeaderTitle,
  Logo,
} from "@dzangolab/react-layout";
import {
  DropdownMenu,
  MenuItem,
  NavigationMenu,
  NavItemType,
  NavMenuItemType,
} from "@dzangolab/react-ui";
import { UserRound } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface HeaderProperties {
  navigationMenu?: NavMenuItemType;
  title?: string | React.ReactNode;
  userMenu: NavItemType[];
  toggleSidebar?: () => void;
}

export const Header = ({
  userMenu,
  navigationMenu,
  title,
  toggleSidebar,
}: HeaderProperties) => {
  const { t } = useTranslation("user");
  const navigate = useNavigate();

  const dropdownMenu: MenuItem[] = userMenu.map((item) => ({
    icon: item.icon as string,
    label: item.label as string,
    ...("onClick" in item && {
      onClick: item.onClick,
    }),
  }));

  return (
    <DzangoHeader>
      <div className="logo-hamburger-container">
        <Logo />
        {toggleSidebar && (
          <span className="dz-toggle-sidebar" onClick={toggleSidebar}>
            <i className={"pi pi-bars"}></i>
          </span>
        )}
      </div>

      {title && (
        <HeaderTitle>
          <span>{title}</span>
          {navigationMenu && (
            <DropdownMenu
              className="mobile-nav-menu"
              label={title}
              menu={navigationMenu.menu.map((item) => ({
                label: item.label as string,
                ...("route" in item && {
                  onClick: () => navigate(item.route),
                }),
              }))}
              trigger={<i className="pi pi-angle-down" />}
            />
          )}
        </HeaderTitle>
      )}
      {navigationMenu && (
        <NavigationMenu
          displayIcons={false}
          horizontal
          navigationMenu={navigationMenu}
        />
      )}

      <DropdownMenu
        className="user-menu"
        hideDropdownIcon
        label={<UserRound size={40} strokeWidth={1} />}
        menu={dropdownMenu}
      />
    </DzangoHeader>
  );
};
