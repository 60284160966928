import {
  Select,
  TextInput,
  useFieldArray,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import {
  Service,
  SERVICE_TYPES,
  ServiceCreateInput,
  ServiceFixtures,
  Workspace,
} from "core";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { EnvironmentVariablesInput } from "../../../../components";
import FixturePicker from "../../../../components/FixturesPicker";
import useServicePresetTemplate from "../../../../hooks/UseServiceTemplates";
import { ServicePresetTemplate } from "../../../../type";

interface Properties {
  handleCancel: () => void;
  isLoading: boolean;
  service?: Service;
  presetTemplates?: ServicePresetTemplate[];
  workspace: Workspace;
  fixtures?: ServiceFixtures[];
  onDelete?: () => void;
  onNameChange?: (name: string) => void;
}

const ServiceFormFields: React.FC<Properties> = ({
  fixtures,
  handleCancel,
  isLoading,
  service,
  onDelete,
  onNameChange,
  presetTemplates,
  workspace,
}) => {
  const { t } = useTranslation("services");
  const {
    setValue,
    formState: { errors },
  } = useFormContext<ServiceCreateInput>();
  const { fields, append, remove } = useFieldArray({ name: "envVars" });

  const watchTypeId = useWatch({ name: "typeId" });
  const watchImage = useWatch({ name: "image" });
  const watchSystemVersion = useWatch({ name: "imageVersion" });
  const watchName = useWatch({ name: "name" });

  const { filteredServicePresets, matchedVersions, selectedPresetService } =
    useServicePresetTemplate(
      presetTemplates,
      watchTypeId,
      watchImage,
      watchSystemVersion,
      service,
      setValue,
    );

  const getErrorMessage = (field: keyof ServiceCreateInput) =>
    (errors[field]?.message as string) || "";

  const appendAttributes = useCallback(() => {
    if (!selectedPresetService?.attributes?.length && !service) {
      remove();

      return;
    }

    const attributes = selectedPresetService?.attributes?.map(
      (attribute: { name: string }) => ({
        name: attribute.name,
        value: "",
      }),
    );

    if (attributes) {
      append(attributes);
    }
  }, [selectedPresetService?.attributes, service, remove, append]);

  useEffect(() => {
    if (!service) appendAttributes();
  }, [appendAttributes, service]);

  useEffect(() => {
    if (!service) {
      setValue("image", "");
      setValue("imageVersion", "");
    }
  }, [watchTypeId, setValue, service]);

  useEffect(() => {
    if (!watchTypeId && service) {
      setValue("typeId", service.typeId);
    }
  }, [service, setValue, watchTypeId]);

  useEffect(() => {
    if (onNameChange) {
      onNameChange(watchName);
    }
  }, [onNameChange, watchName]);

  useEffect(() => {
    if (!service && (!watchTypeId || !watchImage || !watchSystemVersion)) {
      remove();
    }
  }, [watchTypeId, remove, watchImage, watchSystemVersion, service]);

  const copyIdentifier = () => {
    if (service) {
      navigator.clipboard.writeText(service.secondaryIdentifier);
      toast.success(t("messages.success.copyIdentifier"));
    }
  };

  const handleFixtureSelect = (selectedFixture: ServiceFixtures) => {
    setValue("fixtureId", selectedFixture.id);
  };

  const [checked, setChecked] = useState(
    service
      ? service?.volumeMounts
      : !selectedPresetService?.dataPersist?.enabled,
  );

  return (
    <>
      <div className="fields-wrapper form-row">
        <TextInput
          errorMessage={getErrorMessage("name")}
          label={t("form.fields.name")}
          name="name"
        />
        {service && (
          <div className="identifier-input">
            <TextInput
              disabled
              errorMessage={getErrorMessage("secondaryIdentifier")}
              label={t("form.fields.secondaryIdentifier")}
              name="secondaryIdentifier"
            />
            <Button
              iconRight="pi pi-copy"
              onClick={copyIdentifier}
              size="large"
              type="button"
              variant="textOnly"
            />
          </div>
        )}
      </div>

      <div className="fields-wrapper">
        <Select
          disabled={!!service?.typeId}
          label={t("form.fields.typeId.label")}
          name="typeId"
          options={Object.entries(SERVICE_TYPES).map(([key, value]) => ({
            label: t(`form.fields.typeId.options.${key}`),
            value,
          }))}
          placeholder={t("form.fields.typeId.placeholder")}
        />
        <Select
          disabled={!!service?.typeId}
          label={t("form.fields.image.label")}
          name="image"
          options={(filteredServicePresets ?? [])?.map(({ name, image }) => ({
            label: name,
            value: image,
          }))}
          placeholder={t("form.fields.image.placeholder")}
        />
        <Select
          disabled={!!service?.typeId}
          label={t("form.fields.imageVersion.label")}
          name="imageVersion"
          options={matchedVersions}
          placeholder={t("form.fields.imageVersion.placeholder")}
        />
      </div>

      {(selectedPresetService?.hasInitialData ||
        (service && service.fixtureId)) && (
        <FixturePicker
          fixtures={fixtures || []}
          initialValue={service?.fixtureId}
          isDisabled={!!service?.fixtureId}
          label={t("form.fields.fixture.label")}
          onSelect={handleFixtureSelect}
          workspace={workspace}
        />
      )}

      <div className="switch-input-wrapper">
        {(selectedPresetService?.dataPersist || service?.volumeMounts) && (
          <div className="checkbox-wrapper">
            <input
              checked={checked}
              onChange={(event) => {
                const newValue = event.target.checked;

                setChecked(newValue);

                setValue(
                  "volumeMounts",
                  newValue ? selectedPresetService?.dataPersist?.location : "",
                );
              }}
              type="checkbox"
            />
            <label htmlFor="hasPersistentData">
              {t("form.fields.persistDatabase")}
            </label>
          </div>
        )}
      </div>

      <EnvironmentVariablesInput
        addButtonLabel={t("environmentVariablesSection.buttons.add.label")}
        fields={fields}
        inputName="envVars"
        onAdd={() => append({ name: "", value: "" })}
        onRemove={(index) => remove(index)}
        showAddEnvVarButton
        showAdvancedModeButton
        title={<h3>{t("environmentVariablesSection.title")}</h3>}
      />

      <div className="actions-wrapper">
        {service && (
          <Button
            className="delete-button"
            label={t("form.actions.delete")}
            onClick={onDelete}
            severity="danger"
            type="button"
            variant="textOnly"
          />
        )}
        <div className="form-actions">
          <Button
            label={t("form.actions.cancel")}
            onClick={handleCancel}
            severity="secondary"
            type="button"
            variant="outlined"
          />
          <SubmitButton
            label={
              service ? t("form.actions.update") : t("form.actions.submit")
            }
            loading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceFormFields;
