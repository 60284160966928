import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";
import { useNavigate } from "react-router-dom";

interface Properties {
  onClick?: () => void;
}

export const BackButton: React.FC<Properties> = ({ onClick }) => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();

  const canGoBack = window.history.state && window.history.state.idx > 0;

  return (
    <>
      {canGoBack && (
        <Button
          iconLeft={<i className="pi pi-chevron-left"></i>}
          label={t("backButton.label")}
          onClick={onClick || (() => navigate(-1))}
          variant="textOnly"
        ></Button>
      )}
    </>
  );
};

export default BackButton;
