import { useTranslation } from "@dzangolab/react-i18n";
import { TabView } from "@dzangolab/react-ui";
import { Release, ReleaseApp } from "core";

import { ModuleDetails, ModuleEnvironments, ModuleLogs } from "./ModuleTabs";

interface IProperties {
  release: Release;
  module: ReleaseApp;
}

const ReleaseModuleTabView: React.FC<IProperties> = (properties) => {
  const { module, release } = properties;
  const { t } = useTranslation("appOverview");

  const allTabs = [
    {
      label: t("release-app.tabs.details"),
      key: "details",
      children: <ModuleDetails releaseModule={module} />,
      closable: false,
    },
    {
      label: t("release-app.tabs.environment"),
      key: "environment",
      children: <ModuleEnvironments releaseModule={module} />,
      closable: false,
    },
    {
      label: t("release-app.tabs.logs"),
      key: "logs",
      children: <ModuleLogs releaseModule={module} />,
      closable: false,
    },
  ];

  return (
    <div className="module-tab-view">
      <TabView
        activeKey={"details"}
        id={`release-${release.secondaryIdentifier}-${module.slug}`}
        persistState={false}
        tabs={allTabs}
        visibleTabs={["details", "environment", "logs"]}
      />
    </div>
  );
};

export default ReleaseModuleTabView;
