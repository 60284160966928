import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";
import { toast } from "react-toastify";

import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import { useCreateGlossaryMutation } from "../../../../redux/apis/glossary";

interface CreateGlossaryProperties {
  onGlossaryCreate: () => void;
}

const CreateGlossary: React.FC<CreateGlossaryProperties> = (properties) => {
  const { onGlossaryCreate } = properties;
  const { t } = useTranslation("glossary");

  const { workspace: currentWorkspace } = useCurrentWorkspace();

  const [createGlossary, { isLoading: isCreatingGlossary }] =
    useCreateGlossaryMutation();

  const handleStart = async () => {
    let response = await createGlossary({
      workspaceId: currentWorkspace.id,
      data: {
        i18n: [
          {
            name: `${currentWorkspace.name}'s glossary`,
            description: `Glossary for ${currentWorkspace.name ?? "Workspace"}`,
            locale: "en",
          },
        ],
      },
    });

    if ("error" in response) {
      toast.error(t("message.error.default"));
    } else {
      toast.success(t("message.success"));
      onGlossaryCreate();
    }
  };

  return (
    <div className="start-glossary-page">
      <div className="center">
        <div className="container">
          <p>{t("pages.start-glossary.title")}</p>
          <Button
            disabled={isCreatingGlossary}
            label={t("pages.start-glossary.actions.start")}
            onClick={handleStart}
            variant="filled"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateGlossary;
