import { Button } from "@dzangolab/react-ui";
import { useNodeViewContext } from "@prosemirror-adapter/react";
import mermaid from "mermaid";
import React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import type { FC } from "react";

import "./diagam.css";

export const Diagram: FC = () => {
  const { node, setAttrs, selected } = useNodeViewContext();
  const code = useMemo(() => node.attrs.value, [node.attrs.value]);
  const id = node.attrs.identity;
  const codeInput = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState("preview");
  const codePanel = useRef<HTMLDivElement>(null);
  const darkMode = false;
  const rendering = useRef(false);

  const renderMermaid = useCallback(async () => {
    const container = codePanel.current;

    if (!container) return;

    if (code.length === 0) return;
    if (rendering.current) return;

    mermaid.initialize({
      startOnLoad: false,
      theme: darkMode ? "dark" : "default",
    });
    rendering.current = true;
    const { svg, bindFunctions } = await mermaid.render(id, code);

    rendering.current = false;
    container.innerHTML = svg;
    bindFunctions?.(container);
  }, [code, darkMode, id]);

  useEffect(() => {
    requestAnimationFrame(() => {
      renderMermaid();
    });
  }, [renderMermaid, value]);

  return (
    <div className={selected ? "selected-ring" : ""} contentEditable={false}>
      <div className="header">
        <div className="tab-container">
          <Button label="Preview" onClick={() => setValue("preview")} />
          <Button label="Source" onClick={() => setValue("source")} />
        </div>
      </div>
      {value === "preview" && <div className="preview-panel" ref={codePanel} />}
      {value === "source" && (
        <div className="source-panel">
          <textarea
            className="source-textarea"
            defaultValue={code}
            ref={codeInput}
          />
          <Button
            label="Ok"
            onClick={() => {
              setAttrs({ value: codeInput.current?.value || "" });
              setValue("preview");
            }}
          />
        </div>
      )}
    </div>
  );
};
