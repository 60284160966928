import { useTranslation } from "@dzangolab/react-i18n";
import { useState } from "react";

import SectionContainer from "../../../../components/SectionContainer";
import WorkspaceDeleteCard from "../../../../components/WorkspaceDeleteCard";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import DeleteConfirmationModal from "../../DeleteSettings/components/DeleteConfirmationModal";

const SettingsAdvanced = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation("settings");
  const { workspace: currentWorkspace } = useCurrentWorkspace();

  return (
    <>
      <SectionContainer
        subTitle={t("sections.advanced.subTitle")}
        title={t("sections.advanced.title")}
      >
        <WorkspaceDeleteCard
          handleDelete={() => setShowConfirmationModal(true)}
        />
      </SectionContainer>

      {currentWorkspace && (
        <DeleteConfirmationModal
          onHide={() => setShowConfirmationModal(false)}
          visible={showConfirmationModal}
          workspace={currentWorkspace}
        />
      )}
    </>
  );
};

export default SettingsAdvanced;
