import { useTranslation } from "@dzangolab/react-i18n";
import { Page } from "@dzangolab/react-ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLazyGetDocumentTypeQuery } from "../../../../redux/apis/document";
import DocumentTypeForm from "../components/DocumentTypeForm";

const EditDocumentTypes: React.FC = () => {
  const { t } = useTranslation("docs");
  const { workspaceId, id } = useParams();

  const [
    getDocumentType,
    { data: documentType, isFetching: isFetchingGetDocumentType },
  ] = useLazyGetDocumentTypeQuery();

  useEffect(() => {
    if (workspaceId && id) {
      getDocumentType({ workspaceId: Number(workspaceId!), id: Number(id!) });
    }
  }, [workspaceId, id, getDocumentType]);

  return (
    <Page
      loading={isFetchingGetDocumentType}
      title={t("docs-types.actions.update-docs-type")}
    >
      <DocumentTypeForm documentType={documentType} />
    </Page>
  );
};

export default EditDocumentTypes;
