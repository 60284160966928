import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";
import { Release, ReleaseCreateInput, ReleaseType } from "core";
import { toast } from "react-toastify";

import { RELEASE_ALREADY_EXISTS } from "../../../../constants";
import { useDuplicateReleaseMutation } from "../../../../redux/apis/release";
import ReleaseForm from "../ReleaseForm";

interface Properties {
  release?: Release | null;
  releaseTypes?: ReleaseType[];
  onHide: () => void;
  visible: boolean;
  onDuplicate: (secondaryIdentifier: string) => void;
}

const ReleaseFormModal: React.FC<Properties> = (properties) => {
  const { release, releaseTypes, onHide, onDuplicate, visible } = properties;
  const [duplicateRelease, { isLoading }] = useDuplicateReleaseMutation();

  const { t } = useTranslation("release");

  if (!release) {
    return <></>;
  }

  const derivedRelease: ReleaseCreateInput & { type: ReleaseType } = {
    ...release,
    name: `${release.name} copy`,
    description: release.description,
    isDraft: true,
    type: release.type as ReleaseType,
  };

  const onSubmit = async (data: ReleaseCreateInput) => {
    const response = await duplicateRelease({ releaseId: release.id, data });

    if ("error" in response) {
      if ((response as any).error?.data?.error === RELEASE_ALREADY_EXISTS) {
        toast.error(t("duplicate-release-modal.message.already-exists"));
      } else {
        toast.error(t("duplicate-release-modal.message.failed"));
      }
    } else {
      toast.success(t("duplicate-release-modal.message.success"));

      onDuplicate(response.data.secondaryIdentifier);

      onHide();
    }
  };

  return (
    <Modal
      className="release-form-modal"
      header={t("duplicate-release-modal.header")}
      onHide={onHide}
      visible={visible}
    >
      <ReleaseForm
        actionLabel={t("duplicate-release-modal.actions.submit")}
        isSubmitting={isLoading}
        onCancel={onHide}
        onSubmit={onSubmit}
        release={derivedRelease}
        releaseTypes={releaseTypes}
      />
    </Modal>
  );
};

export default ReleaseFormModal;
