import { Provider } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { ConfirmationModal } from "@dzangolab/react-ui";
import { Service, sanitizeVolumeMounts } from "core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import ServiceFormFields from "./ServiceFormFields";
import { useCurrentWorkspace } from "../../../../hooks/UseCurrentWorkspace";
import { useLazyGetFixturesQuery } from "../../../../redux/apis/fixtures";
import {
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
} from "../../../../redux/apis/services";
import { ServicePresetTemplate } from "../../../../type";

interface Properties {
  onCancel: () => void;
  service?: Service;
  servicePresetTemplates?: ServicePresetTemplate[];
  onServiceNameChange?: (name: string) => void;
}

interface ErrorResponse {
  error: {
    status: number;
    data: {
      error: string;
      message: string;
    };
  };
}

interface SuccessResponse {
  data: Service;
}

const ServiceForm: React.FC<Properties> = ({
  onCancel,
  service,
  servicePresetTemplates,
  onServiceNameChange,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation("services");
  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();

  const [triggerAdd, { isLoading: isCreating }] = useCreateServiceMutation();
  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateServiceMutation();
  const [deleteService] = useDeleteServiceMutation();
  const [fetchFixtures, { data: fixtures }] = useLazyGetFixturesQuery();

  const validationSchema = z.object({
    connectionConfigs: z
      .string()
      .refine((value) => {
        try {
          if (value) {
            JSON.parse(value);
          }

          return true;
        } catch (_) {
          return false;
        }
      }, t("form.validations.connectionConfigs.invalid"))
      .transform((value) => (value ? JSON.parse(value) : null))
      .nullable(),
    envVars: z
      .object({
        name: z.string().min(1, t("form.validations.name.required")),
        value: z.union([z.string(), z.number(), z.boolean()]),
      })
      .array(),
    image: z.string().min(1, t("form.validations.image.required")),
    imageVersion: z.string().min(1, t("form.validations.version.required")),
    isExternal: z.boolean(),
    isPublic: z.boolean().nullable(),
    name: z.string().min(1, t("form.validations.name.required")),
    port: z.number().nullable(),
    publicUrl: z.string().nullable(),
    scripts: z.string().nullable(),
    statusId: z.number().nullable(),
    fixtureId: z.number().nullable(),
    typeId: z.number({
      invalid_type_error: t("form.validations.typeId.required"),
      required_error: t("form.validations.typeId.required"),
    }),
    volumeMounts: z.string().nullable(),
  });

  const defaultValues = {
    connectionConfigs: "",
    envVars: [],
    fixtureId: null,
    image: "",
    imageVersion: "",
    isExternal: false,
    isPublic: false,
    name: "",
    port: null,
    publicUrl: null,
    scripts: "",
    slug: "",
    statusId: null,
    typeId: null,
    volumeMounts: "",
  };

  const getInitialValues = () => {
    if (!service) return defaultValues;

    const connectionConfigs = service.connectionConfigs
      ? JSON.stringify(service.connectionConfigs)
      : "";

    const envVariables = service.envVars
      ? Object.keys(service.envVars).map((key) => ({
          name: key,
          value: service.envVars[key] === null ? "null" : service.envVars[key],
        }))
      : [];

    return {
      connectionConfigs,
      envVars: envVariables,
      image: service.image || "",
      imageVersion: service.imageVersion || "",
      isExternal: service.isExternal || false,
      isPublic: service.isPublic || false,
      name: service.name,
      port: service.port,
      publicUrl: service.publicUrl,
      scripts: service.scripts ? service.scripts.join(",") : "",
      fixtureId: service.fixtureId,
      statusId: service.statusId,
      typeId: service.typeId,
      secondaryIdentifier: service.secondaryIdentifier,
      volumeMounts: service.volumeMounts ? service.volumeMounts.join(",") : "",
    };
  };

  const handleSubmit = async (data: z.infer<typeof validationSchema>) => {
    if (!workspace) return;

    let response: SuccessResponse | ErrorResponse;

    try {
      const envVariables = data.envVars.reduce(
        (result: Record<string, string>, { name, value }) => {
          result[name] = String(value);

          return result;
        },
        {},
      );

      const scripts = data.scripts ? data.scripts.split(",") : null;
      const volumeMounts = data.volumeMounts
        ? sanitizeVolumeMounts(data.volumeMounts).split(",")
        : null;

      if (!service) {
        response = (await triggerAdd({
          ...data,
          envVars: envVariables,
          image: data.image || null,
          imageVersion: data.imageVersion || null,
          scripts,
          volumeMounts,
          workspaceId: workspace.id,
        })) as SuccessResponse | ErrorResponse;
      } else {
        response = (await triggerUpdate({
          id: service.id,
          workspaceId: workspace.id,
          data: {
            ...data,
            envVars: envVariables,
            image: data.image || null,
            imageVersion: data.imageVersion || null,
            scripts,
            volumeMounts,
          },
        })) as SuccessResponse | ErrorResponse;
      }

      if ("data" in response) {
        if (!service) {
          const successMessage = t("messages.success.create");

          toast.success(successMessage);
        }

        onCancel();
      } else if ("error" in response) {
        const errorMessage = service
          ? t("messages.error.update")
          : t("messages.error.create");

        toast.error(errorMessage);
      }
    } catch {
      toast.error(t("messages.error.default"));
    }
  };

  const handleServiceDelete = async () => {
    if (!workspace || !service) return;

    try {
      const response = await deleteService({
        id: service.id,
        workspaceId: workspace.id,
      });

      if ("error" in response) {
        const error = (response?.error as any)?.data.error;

        if (error === "SERVICE_ASSOCIATED_WITH_RUNNING_APPS") {
          toast.error(t("messages.error.delete.runningApps"));

          return;
        }

        if (error === "SERVICE_NOT_STOPPED") {
          onCancel();
          toast.error(t("messages.error.delete.notStopped"));

          return;
        }

        toast.error(t("messages.error.delete.default"));
      }

      onCancel();
      setShowDeleteModal(false);
      navigate(
        `/workspaces/${workspace.secondaryIdentifier}/settings#services`,
      );
    } catch {
      toast.error(t("messages.error.delete.default"));
    }
  };

  useEffect(() => {
    if (workspace) {
      fetchFixtures({ workspaceId: workspace.id });
    }
  }, [fetchFixtures, workspace]);

  return (
    <>
      <Provider
        className="service-form"
        defaultValues={getInitialValues()}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <ServiceFormFields
          fixtures={fixtures}
          handleCancel={onCancel}
          isLoading={isCreating || isUpdating}
          onDelete={() => setShowDeleteModal(true)}
          onNameChange={onServiceNameChange}
          presetTemplates={servicePresetTemplates}
          service={service}
          workspace={workspace}
        />
      </Provider>
      <ConfirmationModal
        accept={handleServiceDelete}
        acceptButtonOptions={{
          label: t("delete.confirmation.actions.delete", {
            service: service?.name,
          }),
          severity: "danger",
        }}
        cancelButtonOptions={{
          label: t("delete.confirmation.actions.cancel"),
        }}
        header={t("delete.confirmation.title", {
          service: service?.name,
        })}
        message={t("delete.confirmation.message", {
          service: service?.name,
        })}
        onHide={() => setShowDeleteModal(false)}
        visible={showDeleteModal}
      />
    </>
  );
};

export default ServiceForm;
