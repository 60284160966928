import { Button as TooltipButton } from "@dzangolab/react-ui";
import { commandsCtx } from "@milkdown/core";
import { TooltipProvider, tooltipFactory } from "@milkdown/plugin-tooltip";
import {
  addColAfterCommand,
  addColBeforeCommand,
  addRowAfterCommand,
  addRowBeforeCommand,
  deleteSelectedCellsCommand,
  getCellsInCol,
  getCellsInRow,
  moveColCommand,
  moveRowCommand,
  selectColCommand,
  selectRowCommand,
  selectTableCommand,
  setAlignCommand,
} from "@milkdown/preset-gfm";
import { Plugin, PluginKey } from "@milkdown/prose/state";
import { CellSelection } from "@milkdown/prose/tables";
import { DecorationSet } from "@milkdown/prose/view";
import { useInstance } from "@milkdown/react";
import { $ctx, $prose } from "@milkdown/utils";
import {
  usePluginViewContext,
  useWidgetViewContext,
} from "@prosemirror-adapter/react";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";

import type { Decoration } from "@milkdown/prose/view";
import type { useWidgetViewFactory } from "@prosemirror-adapter/react";
import type { FC } from "react";

export const tableTooltipContext = $ctx<TooltipProvider | null, "tableTooltip">(
  null,
  "tableTooltip",
);

export const tableTooltip = tooltipFactory("TABLE");

export const TableTooltip: FC = () => {
  const reference = useRef<HTMLDivElement>(null);
  const { view } = usePluginViewContext();
  const tooltipProvider = useRef<TooltipProvider>();
  const [loading, getEditor] = useInstance();

  const isRow =
    view.state.selection instanceof CellSelection &&
    view.state.selection.isRowSelection();
  const isCol =
    view.state.selection instanceof CellSelection &&
    view.state.selection.isColSelection();
  const isWholeTable = isRow && isCol;
  const isAny = isRow || isCol;
  const isHeading =
    isRow &&
    view.state.doc.nodeAt((view.state.selection as CellSelection).$headCell.pos)
      ?.type.name === "table_header";

  useEffect(() => {
    if (
      reference.current &&
      !loading &&
      !tooltipProvider.current &&
      view &&
      view.state
    ) {
      const provider = new TooltipProvider({
        content: reference.current,
        tippyOptions: {
          zIndex: 30,
        },
        shouldShow: () => {
          return false;
        },
      });

      provider.update(view);

      const editor = getEditor();

      if (
        editor &&
        editor.ctx &&
        editor.ctx.isInjected(tableTooltipContext.key)
      ) {
        editor.ctx.set(tableTooltipContext.key, provider);
        tooltipProvider.current = provider;
      }
    }

    return () => {
      tooltipProvider.current?.destroy();
    };
  }, [getEditor, loading, view]);

  return (
    <div>
      <div className="table-tooltip" ref={reference}>
        {!isWholeTable && !isHeading && isRow && (
          <TooltipButton
            iconLeft="pi pi-arrow-up"
            onClick={() => {
              if (loading) return;

              getEditor().action((context) => {
                context.get(commandsCtx).call(addRowBeforeCommand.key);
              });
              tooltipProvider.current?.hide();
            }}
          />
        )}
        {!isWholeTable && isCol && (
          <TooltipButton
            iconLeft="pi pi-arrow-left"
            onClick={() => {
              if (loading) return;

              getEditor().action((context) => {
                context.get(commandsCtx).call(addColBeforeCommand.key);
              });
              tooltipProvider.current?.hide();
            }}
          />
        )}
        {(isWholeTable || (!isHeading && isAny)) && (
          <TooltipButton
            iconLeft="pi pi-trash"
            onClick={() => {
              if (loading) return;

              getEditor().action((context) => {
                context.get(commandsCtx).call(deleteSelectedCellsCommand.key);
              });
              tooltipProvider.current?.hide();
            }}
          />
        )}
        {!isWholeTable && isRow && (
          <TooltipButton
            iconLeft="pi pi-arrow-down"
            onClick={() => {
              if (loading) return;

              getEditor().action((context) => {
                context.get(commandsCtx).call(addRowAfterCommand.key);
              });
              tooltipProvider.current?.hide();
            }}
          />
        )}
        {!isWholeTable && isCol && (
          <TooltipButton
            iconLeft="pi pi-arrow-right"
            onClick={() => {
              if (loading) return;
              getEditor().action((context) => {
                context.get(commandsCtx).call(addColAfterCommand.key);
              });

              tooltipProvider.current?.hide();
            }}
          />
        )}
        {!isWholeTable && isCol && (
          <TooltipButton
            iconLeft="pi pi-align-left"
            onClick={() => {
              if (loading) return;
              getEditor().action((context) => {
                context.get(commandsCtx).call(setAlignCommand.key, "left");
              });
            }}
          />
        )}
        {!isWholeTable && isCol && (
          <TooltipButton
            iconLeft="pi pi-align-center"
            onClick={() => {
              if (loading) return;
              getEditor().action((context) => {
                context.get(commandsCtx).call(setAlignCommand.key, "center");
              });
            }}
          />
        )}
        {!isWholeTable && isCol && (
          <TooltipButton
            iconLeft="pi pi-align-right"
            onClick={() => {
              if (loading) return;
              getEditor().action((context) => {
                context.get(commandsCtx).call(setAlignCommand.key, "right");
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

const TableSelectorWidget: FC = () => {
  const { spec } = useWidgetViewContext();
  const type = spec?.type;
  const index = spec?.index ?? 0;
  const [loading, getEditor] = useInstance();
  const reference = useRef<HTMLDivElement>(null);

  const [dragOver, setDragOver] = useState(false);

  const common = useMemo(
    () => ["table-selector-widget", dragOver ? "drag-over" : ""].join(" "),
    [dragOver],
  );

  const className = useMemo(() => {
    if (type === "left") return "left";

    if (type === "top") return "top";

    return "top-left";
  }, [type]);

  return (
    <div
      className={[className, common].join(" ")}
      draggable={type !== "top-left"}
      onClick={(event) => {
        event.stopPropagation();
        const div = reference.current;

        if (loading || !div) return;

        getEditor().action((context) => {
          const tooltip = context.get(tableTooltipContext.key);

          tooltip?.getInstance()?.setProps({
            getReferenceClientRect: () => {
              return div.getBoundingClientRect();
            },
          });
          tooltip?.show();

          const commands = context.get(commandsCtx);

          if (type === "left") commands.call(selectRowCommand.key, index);
          else if (type === "top") commands.call(selectColCommand.key, index);
          else commands.call(selectTableCommand.key);
        });
      }}
      onDragLeave={() => {
        setDragOver(false);
      }}
      onDragOver={(event) => {
        setDragOver(true);
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }}
      onDragStart={(event) => {
        event.stopPropagation();

        const data = { index: spec?.index, type: spec?.type };

        event.dataTransfer.setData(
          "application/milkdown-table-sort",
          JSON.stringify(data),
        );
        event.dataTransfer.effectAllowed = "move";
      }}
      onDrop={(event) => {
        setDragOver(false);
        if (type === "top-left") return;
        const i = spec?.index;

        if (loading || i == null) return;
        const data = event.dataTransfer.getData(
          "application/milkdown-table-sort",
        );

        try {
          const { index, type } = JSON.parse(data);

          getEditor().action((context) => {
            const commands = context.get(commandsCtx);
            const options = {
              from: Number(index),
              to: i,
            };

            commands.call(
              type === "left" ? moveRowCommand.key : moveColCommand.key,
              options,
            );
          });
        } catch {
          // ignore data from other source
        }
      }}
      ref={reference}
    />
  );
};

export const tableSelectorPlugin = (
  widgetViewFactory: ReturnType<typeof useWidgetViewFactory>,
) =>
  $prose(() => {
    const key = new PluginKey("MILKDOWN_TABLE_SELECTOR");

    return new Plugin({
      key,
      state: {
        init() {
          return {
            decorations: DecorationSet.empty,
            pos: 0,
          };
        },
        apply(
          tr,
          value: { decorations: DecorationSet; pos: number },
          oldState,
          newState,
        ) {
          const leftCells = getCellsInCol(0, tr.selection);

          if (!leftCells) return { decorations: DecorationSet.empty, pos: 0 };
          const topCells = getCellsInRow(0, tr.selection);

          if (!topCells) return { decorations: DecorationSet.empty, pos: 0 };

          const createWidget = widgetViewFactory({
            as: "div",
            component: TableSelectorWidget,
          });

          const [topLeft] = leftCells;

          if (!topLeft) return { decorations: DecorationSet.empty, pos: 0 };

          const decorations: Decoration[] = [];

          decorations.push(createWidget(topLeft.pos + 1, { type: "top-left" }));
          leftCells.forEach((cell, index) => {
            decorations.push(
              createWidget(cell.pos + 1, { type: "left", index }),
            );
          });
          topCells.forEach((cell, index) => {
            decorations.push(
              createWidget(cell.pos + 1, { type: "top", index }),
            );
          });

          if (value.pos === topLeft.pos && oldState.doc.eq(newState.doc))
            return value;

          return {
            decorations: DecorationSet.create(tr.doc, decorations),
            pos: topLeft.pos,
          };
        },
      },
      props: {
        decorations(state) {
          return key.getState(state).decorations;
        },
      },
    });
  });
