import { useTranslation } from "@dzangolab/react-i18n";

const TermsAndConditions = () => {
  const { t } = useTranslation("auth");

  return (
    <>
      {t("termsAndConditions.prefix")}
      <a className="native-link" href="./">
        {t("termsAndConditions.infix")}
      </a>
      {t("termsAndConditions.suffix")}
    </>
  );
};

export default TermsAndConditions;
