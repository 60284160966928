import { useEffect } from "react";

const useDisableNumberInputScroll = () => {
  useEffect(() => {
    const onScroll = (event: any) => {
      event.preventDefault();
    };

    const numberInputs = document.querySelectorAll('input[type="number"]');

    numberInputs.forEach((input) => {
      input.addEventListener("wheel", onScroll, { passive: false });
    });

    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener("wheel", onScroll);
      });
    };
  }, []);
};

export default useDisableNumberInputScroll;
