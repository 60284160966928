import {
  NumberInput,
  Select,
  TextInput,
  Textarea,
  useFieldArray,
  useFormContext,
  useWatch,
} from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { Button, SubmitButton } from "@dzangolab/react-ui";
import {
  ReleaseApp,
  ReleaseAppCreateInput,
  ReleaseAppEnginePreset,
  Repository,
  Workspace,
} from "core";
import { useEffect, useMemo } from "react";

import { EnvironmentVariablesInput } from "../../../../components";
import BranchPicker from "../../../../components/BranchPicker";
import RepositoryPicker from "../../../../components/RepositoryPicker";
import config from "../../../../config";
import { useDisableNumberInputScroll } from "../../../../hooks";
import {
  useLazyGetRepositoriesQuery,
  useLazyGetRepositoryBranchesQuery,
} from "../../../../redux/apis/repositories";

interface Properties {
  enginePresets: ReleaseAppEnginePreset[];
  releaseApp?: ReleaseApp;
  workspace: Workspace;
  handleCancel: () => void;
  handleDelete: () => void;
  handleShowBranchRules?: (repositoryId: number) => void;
  isLoading: boolean;
  showDetailsForm: boolean;
  showEnvironmentForm: boolean;
}

const appTypes = [
  {
    label: "Apps",
    value: "apps",
  },
  {
    label: "Apis",
    value: "apis",
  },
];

const AddReleaseAppFormFields: React.FC<Properties> = (properties) => {
  const {
    enginePresets,
    handleCancel,
    handleDelete,
    handleShowBranchRules,
    isLoading,
    releaseApp,
    workspace,
    showDetailsForm,
    showEnvironmentForm,
  } = properties;
  const { t } = useTranslation("appOverview");

  useDisableNumberInputScroll();

  const [fetchRepositories, { data: repositories, isFetching }] =
    useLazyGetRepositoriesQuery();

  const [
    fetchRepositoryBranches,
    { data: branches, isFetching: isFetchingBranches },
  ] = useLazyGetRepositoryBranchesQuery();

  useEffect(() => {
    if (workspace) {
      fetchRepositories({ workspaceId: workspace.id });
    }
  }, [workspace, fetchRepositories]);

  const handleRepositorySelect = (selectedRepository: Repository) => {
    setValue("repositoryId", selectedRepository.id, { shouldValidate: true });
    setValue("branch", undefined);

    fetchRepositoryBranches({
      workspaceId: selectedRepository.workspaceId,
      id: selectedRepository.id,
    });
  };

  const {
    setValue,
    getFieldState,
    formState: { errors },
    register,
  } = useFormContext<ReleaseAppCreateInput>();

  const { fields, append, remove } = useFieldArray({
    name: "envVars",
  });
  const watchName: string = useWatch({ name: "name" });
  const watchBranch: string = useWatch({ name: "branch" });
  const watchEngineName: string = useWatch({ name: "engineName" });
  const watchRepositoryId: number = useWatch({ name: "repositoryId" });

  const engineVersions = useMemo(
    () =>
      enginePresets.find((preset) => preset.key === watchEngineName)
        ?.versions || [],
    [enginePresets, watchEngineName],
  );

  const packageManagers = useMemo(
    () =>
      enginePresets.find((preset) => preset.key === watchEngineName)
        ?.packageManagers || [],
    [enginePresets, watchEngineName],
  );

  const engineTypes = useMemo(() => {
    const selectedPreset = enginePresets.find(
      (preset) => preset.key === watchEngineName,
    );

    return selectedPreset?.types || [];
  }, [enginePresets, watchEngineName]);

  const getErrorMessage = (field: keyof ReleaseAppCreateInput): string => {
    return (errors[field]?.message as string) || "";
  };

  useEffect(() => {
    const { isDirty } = getFieldState("slug");

    if (!isDirty) {
      const generatedSlug = watchName
        ?.trim()
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^[-~!@#$%^&*()]+/, "")
        .replace(/[-~!@#$%^&*()]+$/, "");

      setValue("slug", generatedSlug);
    }
  }, [getFieldState, setValue, watchName]);

  const onInitialRepositorySelect = () => {
    if (releaseApp) {
      fetchRepositoryBranches({
        workspaceId: workspace.id,
        id: releaseApp.repositoryId,
      });

      setValue("branch", releaseApp.branch, { shouldValidate: true });
    }
  };

  return (
    <>
      {showDetailsForm && (
        <>
          <div className="flex-group">
            <TextInput
              errorMessage={getErrorMessage("name")}
              label={t("release-app.add.form.fields.name")}
              name="name"
            />
            {!releaseApp && (
              <TextInput
                errorMessage={getErrorMessage("slug")}
                label={t("release-app.add.form.fields.slug")}
                name="slug"
              />
            )}
          </div>

          <Textarea
            errorMessage={getErrorMessage("description")}
            label={t("release-app.add.form.fields.description")}
            name="description"
          />

          {releaseApp && (
            <Select
              label={t("release-app.add.form.fields.type")}
              name="type"
              options={appTypes}
            />
          )}

          <div className="flex-group">
            <div className="flex-group-item">
              <label>{t("release-app.add.form.fields.repository")}</label>
              {!isFetching && repositories && (
                <RepositoryPicker
                  errorMessage={getErrorMessage("repositoryId")}
                  initialValue={releaseApp?.repositoryId}
                  onInitialization={onInitialRepositorySelect}
                  onSelect={handleRepositorySelect}
                  repositories={repositories as Repository[]}
                  workspace={workspace}
                />
              )}
            </div>
            <div className="flex-group-item">
              <label className="branch-label">
                {t("release-app.add.form.fields.branch")}
                {(watchRepositoryId || releaseApp?.repositoryId) &&
                  config.features.branchFilter && (
                    <button
                      className="link-button"
                      onClick={() => {
                        const repositoryId =
                          watchRepositoryId || releaseApp?.repositoryId;

                        if (repositoryId && handleShowBranchRules) {
                          handleShowBranchRules(repositoryId);
                        }
                      }}
                      type="button"
                    >
                      {t("release-app.view-branch-rules")}
                    </button>
                  )}
              </label>
              <BranchPicker
                branches={
                  !isFetchingBranches && branches && branches.branches
                    ? branches.branches
                    : []
                }
                errorMessage={getErrorMessage("branch")}
                onSelect={(selectedBranch) =>
                  setValue("branch", selectedBranch, { shouldValidate: true })
                }
                value={watchBranch}
              />
            </div>
          </div>

          <Textarea
            errorMessage={getErrorMessage("buildScript")}
            label={t("release-app.add.form.fields.buildScript")}
            name="buildScript"
          />
          <Textarea
            errorMessage={getErrorMessage("runScript")}
            label={t("release-app.add.form.fields.runScript")}
            name="runScript"
          />
          <NumberInput
            errorMessage={getErrorMessage("port")}
            label={t("release-app.add.form.fields.port")}
            name="port"
          />
          <TextInput
            errorMessage={getErrorMessage("sourcePath")}
            label={t("release-app.add.form.fields.sourcePath")}
            name="sourcePath"
          />
          <div className="checkbox-wrapper">
            <input type="checkbox" {...register("isPublic")} />
            <label htmlFor="isPublic">
              {t("release-app.add.form.fields.isPublic")}
            </label>
          </div>

          <div className="flex-group">
            <Select
              label={t("release-app.add.form.fields.engineName.label")}
              name="engineName"
              options={
                enginePresets?.map((preset: any) => {
                  return {
                    label: preset.name,
                    value: preset.key,
                  };
                }) ?? []
              }
              placeholder={t(
                "release-app.add.form.fields.engineName.placeholder",
              )}
            />
            <Select
              autoSelectSingleOption={true}
              label={t("release-app.add.form.fields.engineType.label")}
              name="engineType"
              options={engineTypes.map((type) => {
                return {
                  label: type.name,
                  value: type.key,
                };
              })}
              placeholder={t(
                "release-app.add.form.fields.engineType.placeholder",
              )}
            />
          </div>
          <div className="flex-group">
            <Select
              label={t("release-app.add.form.fields.engineVersion.label")}
              name="engineVersion"
              options={engineVersions.map((version) => {
                return {
                  label: version,
                  value: version,
                };
              })}
              placeholder={t("release-app.add.form.fields.engineVersion.label")}
            />
            <Select
              autoSelectSingleOption={true}
              label={t("release-app.add.form.fields.packageManager.label")}
              name="packageManager"
              options={packageManagers.map((packageManager) => {
                return {
                  label: packageManager,
                  value: packageManager,
                };
              })}
              placeholder={t(
                "release-app.add.form.fields.packageManager.label",
              )}
            />
          </div>

          <Textarea
            errorMessage={getErrorMessage("proxyConfig")}
            label={t("release-app.add.form.fields.proxyConfig.label")}
            name="proxyConfig"
            placeholder={t(
              "release-app.add.form.fields.proxyConfig.placeholder",
            )}
          />
        </>
      )}

      {showEnvironmentForm && (
        <EnvironmentVariablesInput
          addButtonLabel={t("release-app.add.form.fields.envVar.button")}
          errorMessage={getErrorMessage("envVars")}
          fields={fields}
          inputName="envVars"
          onAdd={() => append({ name: "", value: "" })}
          onRemove={(index) => remove(index)}
          showAddEnvVarButton
          showAdvancedModeButton
          title={<h3>{t("release-app.add.form.fields.envVar.title")}</h3>}
        />
      )}

      <div className="actions-wrapper">
        {releaseApp && showDetailsForm && (
          <Button
            className="delete-button"
            label={t("release-app.delete.button.label", {
              appModule: releaseApp.name,
            })}
            onClick={handleDelete}
            severity="danger"
            type="button"
            variant="textOnly"
          />
        )}
        <Button
          label={t("release-app.add.form.fields.cancel")}
          onClick={handleCancel}
          severity="secondary"
          type="button"
          variant="outlined"
        />
        <SubmitButton
          disabled={isFetching}
          label={
            releaseApp
              ? t("release-app.update.button.label")
              : t("release-app.add.form.fields.submit")
          }
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default AddReleaseAppFormFields;
