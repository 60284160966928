import { Select, useFormContext } from "@dzangolab/react-form";
import { useTranslation } from "@dzangolab/react-i18n";
import { SubmitButton } from "@dzangolab/react-ui";
import { SupportedLocales } from "core";

interface Properties {
  supportedLocales: SupportedLocales[];
}

const SelectLocaleFormFields: React.FC<Properties> = (properties) => {
  const { supportedLocales } = properties;
  const { t } = useTranslation("glossary");

  const { register } = useFormContext();

  return (
    <>
      <Select
        autoSelectSingleOption
        label={t("glossary-settings.form.fields.locale.label")}
        name="locale"
        options={supportedLocales.map((locale) => {
          return {
            label: locale.name,
            value: locale.localeCode,
          };
        })}
        placeholder={t("glossary-settings.form.fields.locale.placeholder")}
      />
      <div className="checkbox-wrapper">
        <input type="checkbox" {...register("mainLocale")} />
        <label htmlFor={`mainLocale`}>
          {t("glossary-settings.form.fields.mainLocale.label")}
        </label>
      </div>
      <SubmitButton label={t("glossary-settings.actions.add-locale")} />
    </>
  );
};

export default SelectLocaleFormFields;
