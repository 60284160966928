import { useTranslation } from "@dzangolab/react-i18n";
import {
  Button,
  ConfirmationModal,
  Page,
  TabView,
  Tooltip,
} from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { ReleaseApp, Repository } from "core";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BranchRules } from "./components";
import { GeneralInfo } from "./GeneralInfo";
import BackButton from "../../../components/BackButton";
import config from "../../../config";
import { USER_ROLES } from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../../libs";
import { useLazyGetRuleTypesQuery } from "../../../redux/apis/branch-rules";
import { useLazyGetReleaseTypesQuery } from "../../../redux/apis/release";
import {
  useDeleteRepositoryMutation,
  useLazyGetRepositoryBranchRulesQuery,
  useLazyGetRepositoryQuery,
} from "../../../redux/apis/repositories";

interface SuccessResponse {
  data: {
    id: number;
  };
}

interface ErrorResponse {
  error: {
    data: {
      error: string;
    };
  };
}

const TAB_KEYS = {
  GENERAL: "general",
  BRANCH_RULES: "branch-rules",
};

const RepositoryDetail = () => {
  const { t } = useTranslation("repositoryDetails");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const { user } = useUser();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [getReleaseTypes, { data: releaseTypes }] =
    useLazyGetReleaseTypesQuery();
  const [getRuleTypes, { data: ruleTypes }] = useLazyGetRuleTypesQuery();
  const [getRepository, { data: repository, isFetching: isRepositoryLoading }] =
    useLazyGetRepositoryQuery();
  const [
    fetchBranchRules,
    { data: branchRules, isFetching: isBranchRuleLoading },
  ] = useLazyGetRepositoryBranchRulesQuery();

  const [triggerDelete, { isLoading }] = useDeleteRepositoryMutation();

  const tooltipReference = useRef(null);
  const tabKeys = Object.values(TAB_KEYS);

  const getActiveTabFromHash = () => {
    const tab = location.hash.replace("#", "");

    return tabKeys.includes(tab) ? tab : TAB_KEYS.GENERAL;
  };

  const [activeTab, setActiveTab] = useState(getActiveTabFromHash);

  useEffect(() => {
    if (location.hash) {
      const tab = getActiveTabFromHash();

      if (tab == TAB_KEYS.GENERAL && !config.features.branchFilter) {
        navigate(`#${TAB_KEYS.GENERAL}`, { replace: true });
        setActiveTab(TAB_KEYS.GENERAL);
      } else if (activeTab !== tab) {
        setActiveTab(tab);
      }
    }
  }, [location.hash, activeTab, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentWorkspace && id) {
        await Promise.all([
          getRepository({ workspaceId: currentWorkspace.id, id: Number(id) }),
          getReleaseTypes({ workspaceId: currentWorkspace.id }),
          getRuleTypes({}),
        ]);
      }

      if (currentWorkspace && repository) {
        fetchBranchRules({
          workspaceId: currentWorkspace.id,
          id: repository.id,
        });

        setPageTitle(repository.name);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace, id, repository]);

  const tabs = [
    {
      label: t("title.general"),
      children: (
        <GeneralInfo
          repository={repository as Repository}
          setPageTitle={setPageTitle}
        />
      ),
      key: TAB_KEYS.GENERAL,
    },
    ...(config.features.branchFilter
      ? [
          {
            label: t("title.rules"),
            children: (
              <BranchRules
                branchRules={branchRules}
                isLoading={isBranchRuleLoading}
                releaseTypes={releaseTypes}
                repositoryId={repository?.id!}
                ruleTypes={ruleTypes}
                workspace={currentWorkspace}
              />
            ),
            key: TAB_KEYS.BRANCH_RULES,
          },
        ]
      : []),
  ];

  const handleTabChange = (newTab: string) => {
    navigate(`#${newTab}`, { replace: true });
    setActiveTab(newTab);
  };

  const deleteHandler = async () => {
    if (!repository) return;

    const { id, workspaceId } = repository;

    let response = (await triggerDelete({
      workspaceId,
      repositoryId: id,
    })) as SuccessResponse | ErrorResponse;

    if ("error" in response) {
      const errorMessage =
        response.error.data.error === "ERROR_REPOSITORY_IN_USE"
          ? t("messages.delete.repositoryInUse")
          : t("messages.delete.error");

      toast.error(errorMessage);
    } else {
      navigate(
        `/workspaces/${currentWorkspace.secondaryIdentifier}/settings#repositories`,
      );

      toast.success(t("messages.delete.success"));
    }

    setShowDeleteConfirmation(false);
  };

  const renderToolbar = () => {
    return (
      <>
        {(repository as Repository & { apps?: ReleaseApp[] })?.apps?.length! >
          0 && (
          <Tooltip elementRef={tooltipReference} mouseTrack position="left">
            <span>{t("messages.delete.disable")}</span>
          </Tooltip>
        )}
        <span ref={tooltipReference}>
          <Button
            disabled={
              (repository as Repository & { apps?: ReleaseApp[] })?.apps
                ?.length! > 0
            }
            iconLeft={"pi pi-trash"}
            onClick={() => setShowDeleteConfirmation(true)}
            severity="danger"
          />
        </span>
      </>
    );
  };

  return (
    <Page
      breadcrumb={<BackButton />}
      className="repository-details-page"
      loading={isRepositoryLoading}
      title={pageTitle}
      toolbar={isDeveloper && renderToolbar()}
    >
      <TabView
        activeKey={activeTab}
        id={`repository-detail-${id}`}
        onActiveTabChange={handleTabChange}
        persistState={true}
        tabs={tabs}
        visibleTabs={tabKeys}
      />

      <ConfirmationModal
        accept={deleteHandler}
        acceptButtonOptions={{
          loading: isLoading,
          severity: "danger",
          label: t("confirmation.actions.delete"),
        }}
        cancelButtonOptions={{
          label: t("confirmation.actions.cancel"),
        }}
        header={t("confirmation.header")}
        message={t("confirmation.message", {
          repositoryName: repository?.name,
        })}
        onHide={() => setShowDeleteConfirmation(false)}
        visible={showDeleteConfirmation}
      />
    </Page>
  );
};

export default RepositoryDetail;
