import { createSlice } from "@reduxjs/toolkit";
import { ReleaseApp } from "core";

interface SelectedWorkspaces {
  workspaces: {
    [key: string]: {
      workspace: any;
      selectedRelease: any;
    };
  };
}

const initialState: SelectedWorkspaces = {
  workspaces: {},
};

export const selectedWorkspacesSlice = createSlice({
  name: "selectedWorkspaces",
  initialState,
  reducers: {
    setSelectedWorkspace: (state, action) => {
      let { workspace, release } = action.payload;

      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      if (
        !release &&
        workspaces[workspace.secondaryIdentifier] &&
        workspaces[workspace.secondaryIdentifier].selectedRelease
      ) {
        release = workspaces[workspace.secondaryIdentifier].selectedRelease;
      }

      workspaces[workspace.secondaryIdentifier] = {
        workspace,
        selectedRelease: release,
      };

      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },

    setWorkspaceAndRelease: (state, action) => {
      const { workspaceSecondaryId, workspace, release } = action.payload;

      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      workspaces[workspaceSecondaryId].workspace = workspace;
      workspaces[workspaceSecondaryId].selectedRelease = release;
      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },

    resetSelectedWorkspaces: (state) => {
      state.workspaces = {};
      localStorage.removeItem("workspaces");
    },

    resetSelectedRelease: (state, action) => {
      const { workspace } = action.payload;
      let workspaces = JSON.parse(localStorage.getItem("workspaces") || "{}");

      const { selectedRelease, ...restWorkspaceData } =
        workspaces[workspace.secondaryIdentifier];

      workspaces[workspace.secondaryIdentifier] = {
        ...restWorkspaceData,
      };

      localStorage.setItem("workspaces", JSON.stringify(workspaces));

      state.workspaces = workspaces;
    },

    updateSelectedRelease: (state, action) => {
      const { workspaceId, releaseId, status } = action.payload;

      for (const key in state.workspaces) {
        if (state.workspaces[key].workspace.id === workspaceId) {
          if (
            state.workspaces[key].selectedRelease &&
            state.workspaces[key].selectedRelease.id === releaseId
          ) {
            const updatedSelectedRelease = {
              ...state.workspaces[key].selectedRelease,
              apps: state.workspaces[key].selectedRelease.apps.map(
                (app: ReleaseApp) => ({
                  ...app,
                  status,
                }),
              ),
            };

            state.workspaces[key].selectedRelease = updatedSelectedRelease;

            const workspaces = JSON.parse(
              localStorage.getItem("workspaces") || "{}",
            );

            if (workspaces[key]) {
              workspaces[key].selectedRelease = updatedSelectedRelease;
              localStorage.setItem("workspaces", JSON.stringify(workspaces));
            }
          }
          break;
        }
      }
    },
  },
});

export const {
  setSelectedWorkspace,
  setWorkspaceAndRelease,
  resetSelectedWorkspaces,
  resetSelectedRelease,
  updateSelectedRelease,
} = selectedWorkspacesSlice.actions;

export default selectedWorkspacesSlice.reducer;
