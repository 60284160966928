import { Button, Card, CardBody, CardHeader } from "@dzangolab/react-ui";
import { ReleaseApp, Repository } from "core";
import { useNavigate } from "react-router-dom";

import { USER_ROLES } from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { useHasRole } from "../../../libs";

interface Properties {
  repository: Repository & { apps?: ReleaseApp[] };
  onDelete: (repository: Repository) => void;
}

const RepositoryCard: React.FC<Properties> = ({ repository, onDelete }) => {
  const { id, name, url } = repository;

  const navigate = useNavigate();
  const { workspace } = useCurrentWorkspace();

  const isDeveloper = useHasRole(USER_ROLES.DEVELOPER);

  const onView = () => {
    navigate(
      `/workspaces/${workspace.secondaryIdentifier}/repositories/${id}`,
      {
        state: repository,
      },
    );
  };

  const renderHeader = () => {
    return (
      <>
        <h2>{name}</h2>
        {isDeveloper && (
          <div className="card-action">
            <Button iconLeft="pi pi-eye" onClick={onView} variant="textOnly" />
          </div>
        )}
      </>
    );
  };

  return (
    <Card className="repository-card">
      <CardHeader>{renderHeader()}</CardHeader>
      <CardBody>
        <p>{url}</p>
      </CardBody>
    </Card>
  );
};

export default RepositoryCard;
