import { useTranslation } from "@dzangolab/react-i18n";
import { ReleaseApp } from "core";
import { useEffect } from "react";

import LogViewer from "../../../../../components/LogViewer";
import { useLazyGetReleaseAppLogsQuery } from "../../../../../redux/apis/release";
import Loading from "../../../../Loading";

interface IProperties {
  releaseModule: ReleaseApp;
}

const ModuleLogs: React.FC<IProperties> = (properties) => {
  const { releaseModule } = properties;
  const { t } = useTranslation("appOverview");
  const [fetchReleaseAppLogs, { data: logsData, isFetching: isFetchingLogs }] =
    useLazyGetReleaseAppLogsQuery();

  useEffect(() => {
    if (releaseModule) {
      fetchReleaseAppLogs({
        releaseId: releaseModule.releaseId,
        appId: releaseModule.id,
      });
    }
  }, [fetchReleaseAppLogs, releaseModule]);

  if (isFetchingLogs) {
    return <Loading />;
  }

  if (!logsData || !logsData.logs) {
    return (
      <div className="release-module-logs">
        {t("release-app.logs.message.noLogs")}
      </div>
    );
  }

  return <LogViewer logs={logsData.logs} />;
};

export default ModuleLogs;
