import { Service, ServiceCreateInput } from "core";
import { useEffect, useMemo } from "react";

import { ServicePresetTemplate } from "../type";

function useServicePresetTemplate(
  presetTemplates: ServicePresetTemplate[] | undefined,
  watchTypeId: string,
  watchImage: string,
  watchSystemVersion: string,
  service: Service | undefined,
  setValue: (name: keyof ServiceCreateInput, value: any) => void,
) {
  const filteredServicePresets = useMemo(() => {
    return presetTemplates
      ?.filter((service) => service.type === watchTypeId)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [presetTemplates, watchTypeId]);

  const matchedVersions = useMemo(() => {
    return (
      presetTemplates?.find((item) => item.image === watchImage)?.versions || []
    );
  }, [presetTemplates, watchImage]);

  const selectedPresetService = useMemo<ServicePresetTemplate | null>(() => {
    if (service !== undefined && service !== null) {
      return (
        presetTemplates?.find(
          (presetTemplate) =>
            presetTemplate.image === service.image &&
            presetTemplate.versions.some(
              (version) => version.value === service.imageVersion,
            ) &&
            parseInt(presetTemplate.type) === service.typeId,
        ) || null
      );
    }

    if (!watchTypeId || !watchImage || !watchSystemVersion) {
      return null;
    }

    return (
      presetTemplates?.find(
        (presetTemplate) =>
          presetTemplate.image === watchImage &&
          presetTemplate.versions.some(
            (version) => version.value === watchSystemVersion,
          ) &&
          presetTemplate.type === watchTypeId,
      ) || null
    );
  }, [service, watchImage, watchSystemVersion, watchTypeId, presetTemplates]);

  useEffect(() => {
    if (watchImage && !service) {
      const selectedServiceSystem = filteredServicePresets?.find(
        (servicePreset) => servicePreset.image === watchImage,
      );

      if (selectedServiceSystem?.port) {
        setValue("port", selectedServiceSystem.port);
      }

      if (selectedServiceSystem?.command) {
        setValue("scripts", selectedServiceSystem.command.join(","));
      }

      if (
        selectedServiceSystem?.dataPersist &&
        selectedServiceSystem?.dataPersist.enabled
      ) {
        setValue("volumeMounts", selectedServiceSystem.dataPersist?.location);
      }

      setValue("isPublic", !!selectedServiceSystem?.isPublic);
    }
  }, [watchImage, setValue, service, filteredServicePresets]);

  return {
    filteredServicePresets,
    matchedVersions,
    selectedPresetService,
  };
}

export default useServicePresetTemplate;
