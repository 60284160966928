import { useTranslation } from "@dzangolab/react-i18n";
import { Tag } from "@dzangolab/react-ui";
import { EditableTitle } from "@dzangolab/react-ui";
import { toast } from "react-toastify";

import { useCurrentWorkspace } from "../../hooks/UseCurrentWorkspace";
import { useUpdateReleaseMutation } from "../../redux/apis/release";

const ReleasePageTitle = (properties: {
  isApproved?: boolean;
  title?: string;
  subtitle?: string;
  isEditable?: boolean;
}) => {
  const { t } = useTranslation("release");
  const { release, refetchWorkspaceAndRelease } = useCurrentWorkspace();

  const [triggerUpdate] = useUpdateReleaseMutation();

  const { isEditable } = properties;
  const title = release?.name;
  const subtitle = release?.type?.slug;

  const isDraft = !!release?.isDraft;
  const isClosed = !!release?.closedAt;

  const renderTag = () => {
    if (isClosed) {
      return <Tag color="red" label={t("release-status.closed")} />;
    } else if (isDraft) {
      return <Tag color="blue" label={t("release-status.draft")} />;
    } else if (properties.isApproved) {
      return <Tag color="green" label={t("release-status.approved")} />;
    } else {
      return null;
    }
  };

  const onUpdate = async (title: string) => {
    try {
      if (title !== release?.name) {
        const response = await triggerUpdate({
          id: release.id,
          data: { name: title },
        });

        if ("error" in response) {
          return toast.error(t("messages.error.updateReleaseName"));
        }

        toast.success(t("messages.success.updateReleaseName"));
        refetchWorkspaceAndRelease();
      }
    } catch {
      toast.error(t("messages.error.default"));
    }
  };

  return (
    <span className="release-page-title">
      <p className="subtitle">{properties.subtitle ?? subtitle}</p>
      <div className="title">
        {properties.title || !isEditable ? (
          <h1>{properties.title ?? title}</h1>
        ) : (
          title && (
            <EditableTitle
              allowEdit={isEditable ?? false}
              handleUpdate={onUpdate}
              placeholder={title}
              title={title}
              titleLevel="h1"
            />
          )
        )}
        {renderTag()}
      </div>
    </span>
  );
};

export default ReleasePageTitle;
