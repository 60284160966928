import {
  getUserProtectedRoutes,
  getUserPublicRoutes,
  ProtectedRoutesHandler,
  PublicRoutesHandler,
} from "@dzangolab/react-user";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { feature } from "./config";
import { ROUTES } from "./constants";
import { BasicLayout, PublicLayout, WorkspaceLayout } from "./layouts";
import Dashboard from "./views/Dashboard";
import DataModel from "./views/DataModel";
import Documents from "./views/Docs";
import Document from "./views/Docs/Document";
import DocumentPlayground from "./views/DocsPlayground";
import Glossary from "./views/Glossary";
import AddFirstTermPage from "./views/Glossary/AddFirstTerm";
import AddGlossary from "./views/Glossary/AddGlossary";
import AddGlossaryEntry from "./views/Glossary/AddGlossaryEntry";
import EditGlossaryEntry from "./views/Glossary/EditGlossaryEntry";
import NotFound from "./views/NotFound";
import Overview from "./views/Overview";
import ReleaseApp from "./views/ReleaseApp";
import AddRepository from "./views/Repositories/Create";
import RepositoryDetail from "./views/Repositories/detail";
import { DetailedService } from "./views/Services";
import Settings from "./views/Settings";
import DocumentationSettings from "./views/Settings/DocumentationSettings";
import CreateDocumentTypes from "./views/Settings/DocumentationSettings/CreateDocumentTypes";
import EditDocumentTypes from "./views/Settings/DocumentationSettings/EditDocumentTypes";
import ReleaseTypeSettings from "./views/Settings/ReleaseType";
import Team from "./views/Team";
import WorkspaceReleases from "./views/WorkspaceReleases";
import Workspaces from "./views/Workspaces";
import AddWorkspace from "./views/Workspaces/Create";

export const AppRouter = () => {
  const renderDocumentationRoutes = () => {
    return (
      <>
        <Route
          element={<CreateDocumentTypes />}
          path={ROUTES.WORKSPACE_DOCUMENT_TYPE_CREATE}
        />
        <Route element={<DocumentPlayground />} path={ROUTES.DOCS_PLAYGROUND} />
        <Route
          element={<EditDocumentTypes />}
          path={ROUTES.WORKSPACE_DOCUMENT_TYPE_EDIT}
        />
        <Route element={<Documents />} path={ROUTES.WORKSPACE_DOCS} />
        <Route element={<Document />} path={ROUTES.WORKSPACE_DOCUMENT} />
        <Route
          element={<DocumentationSettings />}
          path={ROUTES.WORKSPACE_SETTINGS_DOCUMENTATION}
        />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        {/* auth routes */}
        <Route element={<ProtectedRoutesHandler />}>
          <Route element={<BasicLayout />}>
            <Route element={<Workspaces />} path={ROUTES.WORKSPACES}></Route>
            <Route
              element={<AddWorkspace />}
              path={ROUTES.WORKSPACE_CREATE}
            ></Route>

            {getUserProtectedRoutes()}
          </Route>

          <Route element={<WorkspaceLayout />}>
            <Route element={<Dashboard />} index />
            <Route element={<ReleaseApp />} path={ROUTES.RELEASE_APP} />
            <Route element={<DetailedService />} path={ROUTES.SERVICE} />
            <Route element={<Glossary />} path={ROUTES.WORKSPACE_GLOSSARY} />
            <Route
              element={<AddFirstTermPage />}
              path={ROUTES.WORKSPACE_GLOSSARY_ADD_FIRST_TERM}
            />
            <Route
              element={<AddGlossary />}
              path={ROUTES.WORKSPACE_GLOSSARY_NEW}
            />
            <Route
              element={<EditGlossaryEntry />}
              path={ROUTES.WORKSPACE_GLOSSARY_ENTRY_EDIT}
            />
            <Route
              element={<AddGlossaryEntry />}
              path={ROUTES.WORKSPACE_GLOSSARY_ENTRY_NEW}
            />
            <Route element={<DataModel />} path={ROUTES.WORKSPACE_DATA_MODEL} />
            <Route element={<Overview />} path={ROUTES.WORKSPACE_RELEASE} />
            <Route element={<Team />} path={ROUTES.WORKSPACE_TEAM} />
            <Route element={<Settings />} path={ROUTES.WORKSPACE_SETTINGS} />
            <Route
              element={<WorkspaceReleases />}
              path={ROUTES.WORKSPACE_RELEASES}
            />
            <Route
              element={<AddRepository />}
              path={ROUTES.WORKSPACE_REPOSITORY_CREATE}
            />
            <Route
              element={<RepositoryDetail />}
              path={ROUTES.WORKSPACE_REPOSITORY_DETAIL}
            />
            <Route
              element={<ReleaseTypeSettings />}
              path={ROUTES.WORKSPACE_RELEASE_TYPE}
            />

            {feature("documentation") && renderDocumentationRoutes()}
          </Route>
        </Route>

        {/* public routes */}
        <Route element={<PublicLayout />}>
          <Route element={<PublicRoutesHandler />}>
            {getUserPublicRoutes()}
          </Route>
        </Route>

        <Route element={<NotFound />} path="*" />
      </Routes>
    </Router>
  );
};
