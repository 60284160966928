import React from "react";

interface LogViewerProperties {
  logs: string;
}

const LogViewer: React.FC<LogViewerProperties> = ({ logs }) => {
  if (!logs) {
    return null;
  }

  const logLines = logs.split("\n");

  const filteredLogLines = logLines.filter((line) => line.trim() !== "");

  return (
    <pre className="log-viewer">
      {filteredLogLines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </pre>
  );
};

export default LogViewer;
