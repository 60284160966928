import { useTranslation } from "@dzangolab/react-i18n";
import { Button } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LocaleSetting, CreateGlossary } from "./components";
import { USER_ROLES } from "../../../constants";
import { useCurrentWorkspace } from "../../../hooks/UseCurrentWorkspace";
import { hasRole } from "../../../libs";
import {
  useLazyGetGlossaryQuery,
  useLazyGetSupportedLocalesQuery,
  useLazyGetWorkspaceGlossaryQuery,
} from "../../../redux/apis/glossary";
import Loading from "../../Loading";

const GlossarySettings = () => {
  const { t } = useTranslation("glossary");
  const { user } = useUser();
  const navigate = useNavigate();
  const { workspace: currentWorkspace } = useCurrentWorkspace();
  const [showCreateGlossary, setShowCreateGlossary] = useState(false);

  const [fetchWorkspaceGlossary, { data: workspaceGlossary }] =
    useLazyGetWorkspaceGlossaryQuery();
  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  const [fetchGlossary, { data: glossary, isLoading: isLoadingGlossary }] =
    useLazyGetGlossaryQuery();

  const [
    fetchSupportedLocales,
    { data: supportedLocales, isLoading: isLoadingSupportedLocales },
  ] = useLazyGetSupportedLocalesQuery();

  const onGlossaryCreate = useCallback(() => {
    setShowCreateGlossary(false);
  }, []);

  const navigateToGlossary = () => {
    if (currentWorkspace && currentWorkspace.secondaryIdentifier && glossary) {
      navigate(`/workspaces/${currentWorkspace.secondaryIdentifier}/glossary`);
    }
  };

  useEffect(() => {
    if (currentWorkspace) {
      fetchWorkspaceGlossary(currentWorkspace.id).then((response: any) => {
        if (
          "error" in response &&
          response.error.status === 404 &&
          response.error.data.error === "ERROR_WORKSPACE_GLOSSARY_NOT_FOUND"
        ) {
          setShowCreateGlossary(true);
        }
      });
    }
  }, [currentWorkspace, fetchWorkspaceGlossary]);

  useEffect(() => {
    if (workspaceGlossary && workspaceGlossary.glossaryId) {
      fetchGlossary(workspaceGlossary.glossaryId);
    }
  }, [fetchGlossary, workspaceGlossary]);

  useEffect(() => {
    fetchSupportedLocales();
  }, [fetchSupportedLocales]);

  if (isLoadingGlossary || isLoadingSupportedLocales) {
    return <Loading />;
  }

  if (showCreateGlossary) {
    return <CreateGlossary onGlossaryCreate={onGlossaryCreate} />;
  }

  return (
    <div className="settings-glossary">
      <div className="title-wrapper">
        {isDeveloper && (
          <Button
            label={t("glossary-settings.actions.goto-glossary")}
            onClick={navigateToGlossary}
          />
        )}
      </div>
      {glossary && supportedLocales && (
        <LocaleSetting
          glossary={glossary}
          supportedLocales={supportedLocales ?? []}
        />
      )}
    </div>
  );
};

export default GlossarySettings;
