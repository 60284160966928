/* eslint-disable no-console */
import { useTranslation } from "@dzangolab/react-i18n";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Id, toast } from "react-toastify";

import { useCurrentWorkspace } from "./UseCurrentWorkspace";
import config from "../config";
import { useLazyGetServicesQuery } from "../redux/apis/services";
import WebSocketManager from "../WebSocketManager";

export const useWebSocket = () => {
  const { t } = useTranslation("release");
  const [message, setMessage] = useState<string | null>(null);
  const [deploymentToastId, setDeploymentToastId] = useState<Id | null>(null);
  const [fetchServices] = useLazyGetServicesQuery();
  const location = useLocation();

  const { refetchWorkspaceAndRelease } = useCurrentWorkspace();

  const handleRouteChange = useCallback(() => {
    const workspaceSlug = extractWorkspaceSlug(location.pathname);

    const manager = WebSocketManager.getInstance();

    if (workspaceSlug) {
      manager.connect(config.apiWebsocketUrl, workspaceSlug);

      const socket = manager.getSocket();

      if (socket) {
        socket.onmessage = (event) => {
          setMessage(event.data);
        };
      }
    } else {
      manager.close();
    }
  }, [location]);

  useEffect(() => {
    handleRouteChange();
  }, [handleRouteChange]);

  useEffect(() => {
    if (message) {
      handleWebSocketMessage(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const extractWorkspaceSlug = (pathname: string): string | null => {
    const match = pathname.match(/^\/workspaces\/([^\/]+)\/[^\/]+/);

    return match ? match[1] : null;
  };

  const handleWebSocketMessage = (message: string) => {
    const {
      type,
      message: notificationMessage,
      releaseName,
      stepsCompleted,
      totalSteps,
      nextStepName,
      targetState,
      workspaceId,
      eventType,
    } = JSON.parse(message);

    if (notificationMessage === "deploy_message") {
      updateDeploymentToast(stepsCompleted, totalSteps, nextStepName, {
        releaseSlug: releaseName,
      });
    } else {
      let i18nOptions: { releaseSlug: string; targetState?: string } = {
        releaseSlug: releaseName,
      };

      const messageKey = getNotificationMessageKey(notificationMessage);

      if (messageKey) {
        if (notificationMessage === "release_state_updated") {
          const targetReleaseState = targetState.i18n[0]
            ? targetState.i18n[0].name
            : "";

          i18nOptions = {
            ...i18nOptions,
            targetState: targetReleaseState,
          };
        }

        switch (type) {
          case "success":
            toast.success(t(messageKey, i18nOptions));
            break;

          case "error":
            toast.error(t(messageKey, i18nOptions));
            break;

          case "info":
            toast.info(t(messageKey, i18nOptions));
            break;

          default:
            toast.info(t(messageKey, i18nOptions));
            break;
        }
      }

      if (
        (eventType === "service_status_updated" ||
          eventType === "service_deleted") &&
        workspaceId
      ) {
        fetchServices({ workspaceId: workspaceId });
      }

      if (eventType === "release_module_status_updated") {
        refetchWorkspaceAndRelease();
      }

      if (eventType === "release_module_stopped") {
        refetchWorkspaceAndRelease();
        toast.success(t("messages.success.stop", i18nOptions));
      }
    }
  };

  const updateDeploymentToast = (
    stepsCompleted: number,
    totalSteps: number,
    nextStepName: string,
    i18nOptions?: Record<string, string>,
  ) => {
    const newStep = `${t(
      "deployment-steps.label",
    )}: ${stepsCompleted}/${totalSteps} ${t(
      "deployment-steps.completed",
    )} - ${t(`deployment-steps.${nextStepName}`).toLocaleLowerCase()}`;

    const progress = stepsCompleted / totalSteps;

    if (deploymentToastId) {
      toast.update(deploymentToastId, {
        render: newStep,
        progress,
        onClose: () => setDeploymentToastId(null),
      });
    } else {
      const id = toast.info(newStep, {
        autoClose: false,
        draggable: true,
        onClose: () => setDeploymentToastId(null),
      });

      setDeploymentToastId(id);
    }

    if (stepsCompleted === totalSteps && deploymentToastId) {
      refetchWorkspaceAndRelease();

      toast.update(deploymentToastId, {
        render: t("deployment-steps.deployment-completed"),
        progress: 1,
        autoClose: 5000,
        onClose: () => setDeploymentToastId(null),
      });

      toast.success(t("messages.success.start", i18nOptions));
    }
  };

  const getNotificationMessageKey = (message: string) => {
    switch (message) {
      case "new_release_available":
        return "messages.success.new-release-available";

      case "latest_changes_deployed":
        return "messages.success.latest-changes-deployed";

      case "release_preparing":
        return "messages.success.release-preparing";

      case "release_state_updated":
        return "messages.success.release-state-updated";

      case "release_prepare_completed":
        refetchWorkspaceAndRelease();

        return "messages.success.release-prepare-completed";

      default:
        return message;
    }
  };

  return { message };
};
