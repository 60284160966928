import { Copyright, Version } from "@dzangolab/react-layout";

export const Footer = () => {
  return (
    <div className="layout-footer">
      <div className="footer-inner">
        <Copyright />
        <Version />
      </div>
    </div>
  );
};
