import { Release, ReleaseApp, WORKSPACE_RELEASE_STATUS } from "core";

export const getReleaseStatusLabel = (release: Release, t: any) => {
  if (release.isDraft) {
    return t("release-status.draft");
  }

  if (release.closedAt) {
    return t("release-status.closed");
  }

  const apps = release?.apps ?? [];

  const statusPriority = Object.values(WORKSPACE_RELEASE_STATUS);

  for (const status of statusPriority) {
    if (status === WORKSPACE_RELEASE_STATUS.STOPPED) {
      if (apps.every((app: ReleaseApp) => app.status === status)) {
        return status;
      }
    } else if (apps.some((app: ReleaseApp) => app.status === status)) {
      return status;
    }
  }

  return "";
};
