import { useTranslation } from "@dzangolab/react-i18n";
import { Service } from "core";
import React, { useEffect } from "react";

import LogViewer from "../../../../components/LogViewer";
import { useLazyGetServiceLogsQuery } from "../../../../redux/apis/services";
import Loading from "../../../Loading";

interface IProperties {
  service: Service;
}

const ServiceLogs: React.FC<IProperties> = (properties) => {
  const { service } = properties;
  const { t } = useTranslation("services");

  const [fetchServiceLogs, { data: logsData, isFetching: isFetchingLogs }] =
    useLazyGetServiceLogsQuery();

  useEffect(() => {
    if (service) {
      fetchServiceLogs({
        workspaceId: service.workspaceId,
        serviceId: service.id,
      });
    }
  }, [fetchServiceLogs, service]);

  if (isFetchingLogs) {
    return <Loading />;
  }

  if (!logsData || !logsData.logs) {
    return (
      <div className="service-logs">{t("messages.no-logs-available")}</div>
    );
  }

  return <LogViewer logs={logsData.logs} />;
};

export default ServiceLogs;
