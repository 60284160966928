import { State } from "@12degrees/stateful-core";
import { TRequestJSON } from "@dzangolab/react-ui";
import {
  Release,
  ReleaseApp,
  ReleaseAppCreateInput,
  ReleaseAppEnginePreset,
  ReleaseAppUpdateInput,
  ReleaseCreateInput,
  ReleaseType,
  ReleaseTypeCreateInput,
  ReleaseTypeUpdateInput,
  ReleaseUpdateInput,
  WORKSPACE_RELEASE_STATUS,
} from "core";

import baseApi, { TagsEnum } from "./base-api";
import { updateSelectedRelease } from "../SelectedWorkspacesSlice";
import { encodeURIParameter } from "../util";

const releaseApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addReleaseApp: build.mutation<ReleaseApp, ReleaseAppCreateInput>({
      query: (data: ReleaseAppCreateInput) => {
        return {
          url: `releases/${data.releaseId}/apps`,
          method: "POST",
          body: data,
        };
      },
    }),

    createRelease: build.mutation<Release, ReleaseCreateInput>({
      query: (data) => {
        return {
          url: "releases",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : [TagsEnum.Workspace]),
    }),

    createReleaseType: build.mutation<ReleaseType, ReleaseTypeCreateInput>({
      query: (data: ReleaseTypeCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),

    deleteReleaseType: build.mutation<
      string,
      { workspaceId: number; id: number }
    >({
      query: (data) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types/${data.id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: string) => response,
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),

    deleteRelease: build.mutation<string, any>({
      query: (id: number) => {
        return {
          url: `/releases/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: (response: string) => response,
      invalidatesTags: [TagsEnum.Release],
    }),

    deleteReleaseApp: build.mutation<
      void,
      { releaseId: number; appId: number }
    >({
      query: ({ releaseId, appId }) => {
        return {
          url: `releases/${releaseId}/apps/${appId}`,
          method: "DELETE",
        };
      },
    }),

    deployRelease: build.query<any, { workspaceId: number; releaseId: number }>(
      {
        query: (input) => {
          return {
            url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/deploy`,
          };
        },
        async onQueryStarted(row, { dispatch, queryFulfilled }) {
          dispatch(
            updateSelectedRelease({
              workspaceId: row.workspaceId,
              releaseId: row.releaseId,
              status: WORKSPACE_RELEASE_STATUS.STARTING,
            }),
          );

          const patchResult = dispatch(
            releaseApi.util.updateQueryData(
              "getWorkspaceReleases",
              { workspaceId: row.workspaceId },
              (draft) => {
                const index = draft.findIndex(
                  (item) => item.id === row.releaseId,
                );

                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    apps: draft[index].apps.map((app) => ({
                      ...app,
                      status: WORKSPACE_RELEASE_STATUS.STARTING,
                    })),
                  };
                }
              },
            ),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      },
    ),

    duplicateRelease: build.mutation<
      Release,
      { releaseId: number; data: ReleaseCreateInput }
    >({
      query: ({ releaseId, data }) => {
        return {
          url: `releases/${releaseId}/duplicate`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Release],
    }),

    getRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),

    getReleaseStates: build.query<State[], void>({
      query: () => {
        return {
          url: `release/states`,
        };
      },
      providesTags: [TagsEnum.ReleaseStates],
    }),

    getReleaseTypes: build.query<
      ReleaseType[],
      { workspaceId: number; filters?: Record<string, string> }
    >({
      query: (input) => {
        const filterQuery = input.filters
          ? `?filter=${JSON.stringify(input.filters)}`
          : "";

        return {
          url: `workspaces/${input.workspaceId}/release-types${filterQuery}`,
        };
      },
      providesTags: [TagsEnum.ReleaseTypes],
    }),

    getReleaseBySecondaryIdentifier: build.query<
      any,
      { workspaceId: number; releaseSecondaryId: string }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/secondaryId/${input.releaseSecondaryId}`,
        };
      },
      providesTags: [TagsEnum.Release],
    }),

    getWorkspaceReleases: build.query<
      Release[],
      { workspaceId: number; filters?: TRequestJSON["filter"] }
    >({
      query: ({ workspaceId, filters }) => {
        return {
          url: `workspaces/${workspaceId}/releases`,
          params: filters
            ? {
                filters: encodeURIParameter(filters),
              }
            : undefined,
        };
      },
      providesTags: [TagsEnum.Releases],
    }),

    getReleaseType: build.query<
      ReleaseType,
      { workspaceId: number; id: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/release-types/${input.id}`,
        };
      },
      providesTags: [TagsEnum.ReleaseTypes],
    }),

    getReleaseAppLogs: build.query<any, { releaseId: number; appId: number }>({
      query: (input) => {
        return {
          url: `/releases/${input.releaseId}/apps/${input.appId}/logs`,
        };
      },
      transformResponse: (response: string) => response,
    }),

    getReleaseAppEnginePresetTemplates: build.query<
      ReleaseAppEnginePreset[],
      void
    >({
      query: () => {
        return {
          url: `/releases/engine-presets`,
        };
      },
      providesTags: ["ReleaseAppEnginePresetTemplates"],
    }),

    linkReleaseService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/service`,
          method: "POST",
          body: {
            releaseAppId: releaseAppId,
            workspaceServiceId: workspaceServiceId,
          },
        };
      },
    }),

    markReleaseAsReady: build.mutation<
      any,
      { workspaceId: number; releaseId: number }
    >({
      query: ({ workspaceId, releaseId }) => {
        return {
          url: `workspaces/${workspaceId}/releases/${releaseId}/ready`,
          method: "POST",
        };
      },
    }),

    setupAndBuildRelease: build.query<
      any,
      { workspaceId: number; releaseId: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/prepare`,
        };
      },
      async onQueryStarted(row, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          releaseApi.util.updateQueryData(
            "getWorkspaceReleases",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === row.releaseId,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  apps: draft[index].apps.map((app) => ({
                    ...app,
                    status: WORKSPACE_RELEASE_STATUS.BUILDING,
                  })),
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),

    stopRelease: build.query<any, { workspaceId: number; releaseId: number }>({
      query: (input) => ({
        url: `workspaces/${input.workspaceId}/releases/${input.releaseId}/stop`,
      }),
      async onQueryStarted(row, { dispatch, queryFulfilled }) {
        dispatch(
          updateSelectedRelease({
            workspaceId: row.workspaceId,
            releaseId: row.releaseId,
            status: WORKSPACE_RELEASE_STATUS.STOPPING,
          }),
        );

        const patchResult = dispatch(
          releaseApi.util.updateQueryData(
            "getWorkspaceReleases",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === row.releaseId,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  apps: draft[index].apps.map((app) => ({
                    ...app,
                    status: WORKSPACE_RELEASE_STATUS.STOPPING,
                  })),
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),

    updateRelease: build.mutation<
      Release,
      { id: number; data: Partial<ReleaseUpdateInput> }
    >({
      query: ({ id, data }) => {
        return {
          url: `releases/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : [TagsEnum.Workspace]),
    }),

    updateReleaseApp: build.mutation<
      ReleaseApp,
      { id: number; data: ReleaseAppUpdateInput }
    >({
      query: ({ id, data }) => {
        return {
          url: `releases/${data.releaseId}/apps/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),

    updateReleaseState: build.mutation<
      { releaseRecord: any },
      { releaseId: number; transitionId: number }
    >({
      query: ({ releaseId, transitionId }) => {
        return {
          url: `/releases/${releaseId}/transitions/${transitionId}`,
          method: "POST",
        };
      },
      invalidatesTags: [TagsEnum.ReleaseStates],
    }),

    updateReleaseType: build.mutation<
      ReleaseType,
      { id: number; data: ReleaseTypeUpdateInput }
    >({
      query: ({ id, data }) => {
        return {
          url: `workspaces/${data.workspaceId}/release-types/${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.ReleaseTypes],
    }),

    unlinkService: build.mutation<
      any,
      { releaseId: number; workspaceServiceId: number; releaseAppId: number }
    >({
      query: ({ releaseAppId, releaseId, workspaceServiceId }) => {
        return {
          url: `releases/${releaseId}/services/unlink`,
          method: "DELETE",
          body: {
            releaseAppId,
            workspaceServiceId,
          },
        };
      },
    }),
  }),
});

export const {
  useAddReleaseAppMutation,
  useLinkReleaseServiceMutation,
  useCreateReleaseMutation,
  useUpdateReleaseMutation,
  useDeleteReleaseMutation,
  useDeleteReleaseAppMutation,
  useDuplicateReleaseMutation,
  useMarkReleaseAsReadyMutation,
  useLazyGetReleaseAppEnginePresetTemplatesQuery,
  useLazyGetReleaseQuery,
  useLazyGetReleaseBySecondaryIdentifierQuery,
  useLazyGetReleaseStatesQuery,
  useUpdateReleaseStateMutation,
  useLazyDeployReleaseQuery,
  useLazyStopReleaseQuery,
  useLazySetupAndBuildReleaseQuery,
  useUnlinkServiceMutation,
  useLazyGetReleaseTypesQuery,
  useCreateReleaseTypeMutation,
  useUpdateReleaseTypeMutation,
  useLazyGetReleaseTypeQuery,
  useUpdateReleaseAppMutation,
  useDeleteReleaseTypeMutation,
  useLazyGetReleaseAppLogsQuery,
  useLazyGetWorkspaceReleasesQuery,
} = releaseApi;

export default releaseApi;
