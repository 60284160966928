import { useTranslation } from "@dzangolab/react-i18n";
import { Button, Card, CardBody, CardHeader, Page } from "@dzangolab/react-ui";
import { useUser } from "@dzangolab/react-user";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES, SESSION_STORAGE_KEYS, USER_ROLES } from "../../constants";
import { hasRole } from "../../libs";
import { useLazyGetWorkspacesQuery } from "../../redux/apis/workspaces";
import { resetSelectedWorkspaces } from "../../redux/SelectedWorkspacesSlice";

import type { Workspace } from "core";

const Workspaces = () => {
  const [fetchWorkspaces, { data: workspaces }] = useLazyGetWorkspacesQuery();
  const { t } = useTranslation("workspaces");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();

  const isDeveloper = hasRole(user, USER_ROLES.DEVELOPER);

  useEffect(() => {
    const fetchDataAndNavigate = async () => {
      try {
        const response = await fetchWorkspaces();

        if (!response.data || response.data?.length === 0) {
          navigate(ROUTES.WORKSPACE_CREATE);
        }

        const hasRedirected = sessionStorage.getItem(
          SESSION_STORAGE_KEYS.WORKSPACE_REDIRECTED_AFTER_LOGIN,
        );

        if (!isDeveloper && response.data?.length === 1 && !hasRedirected) {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.WORKSPACE_REDIRECTED_AFTER_LOGIN,
            "true",
          );
          navigate(
            `/workspaces/${response.data[0].secondaryIdentifier}/releases`,
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error fetching workspaces:", err);
      }
    };

    // Call the async function
    fetchDataAndNavigate();
  }, [fetchWorkspaces, navigate]);

  return (
    <Page
      className="workspaces-page"
      title={t("title")}
      toolbar={
        isDeveloper && (
          <Button onClick={() => navigate(ROUTES.WORKSPACE_CREATE)}>
            <i className="pi pi-plus"></i>
          </Button>
        )
      }
    >
      {workspaces &&
        workspaces.map((workspace: Workspace) => (
          <div
            className="card-wrapper"
            key={workspace.id}
            onClick={() => {
              dispatch(resetSelectedWorkspaces());
              navigate(`/workspaces/${workspace.secondaryIdentifier}/releases`);
            }}
          >
            <Card outlined>
              <CardHeader>{<h2>{workspace.name}</h2>}</CardHeader>

              <CardBody>
                {workspace.description ? <p>{workspace.description}</p> : ""}
              </CardBody>
            </Card>
          </div>
        ))}
    </Page>
  );
};

export default Workspaces;
