import { useTranslation } from "@dzangolab/react-i18n";
import { Modal } from "@dzangolab/react-ui";

import { ServicePresetTemplate } from "../../../../../type";
import { ServiceForm } from "../../../../Services/components";

interface Properties {
  onHide: () => void;
  visible: boolean;
  servicePresetTemplates: ServicePresetTemplate[];
}

const ServiceFormModal: React.FC<Properties> = ({
  onHide,
  servicePresetTemplates,
  visible,
}) => {
  const { t } = useTranslation("services");

  return (
    <Modal
      className="service-form-modal"
      header={t("modal.header.new")}
      onHide={onHide}
      visible={visible}
    >
      <ServiceForm
        onCancel={onHide}
        servicePresetTemplates={servicePresetTemplates}
      />
    </Modal>
  );
};

export default ServiceFormModal;
