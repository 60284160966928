import { Button } from "@dzangolab/react-ui";
import { CmdKey } from "@milkdown/core";
import { tooltipFactory, TooltipProvider } from "@milkdown/plugin-tooltip";
import {
  toggleEmphasisCommand,
  toggleStrongCommand,
  wrapInBlockquoteCommand,
} from "@milkdown/preset-commonmark";
import { toggleStrikethroughCommand } from "@milkdown/preset-gfm";
import { useInstance } from "@milkdown/react";
import { callCommand } from "@milkdown/utils";
import { usePluginViewContext } from "@prosemirror-adapter/react";
import { useEffect, useRef } from "react";
import React from "react";

import "../tooltip.css";

export const tooltip = tooltipFactory("Text");

export const TooltipView = () => {
  const divReference = useRef<HTMLDivElement>(null);
  const tooltipProvider = useRef<TooltipProvider>();

  const { view, prevState } = usePluginViewContext();
  const [loading, get] = useInstance();

  useEffect(() => {
    const div = divReference.current;

    if (loading || !div) {
      return;
    }
    tooltipProvider.current = new TooltipProvider({
      content: div,
    });

    return () => {
      tooltipProvider.current?.destroy();
    };
  }, [loading]);

  useEffect(() => {
    tooltipProvider.current?.update(view, prevState);
  });

  function call<T>(command: CmdKey<T>, payload?: T) {
    return get()?.action(callCommand(command, payload));
  }

  return (
    <div>
      <div className="tooltip-wrapper" ref={divReference}>
        <Button
          className="toolbar-button"
          label="B"
          onMouseDown={(event) => {
            call(toggleStrongCommand.key);
            event.preventDefault();
          }}
          size="small"
          variant="textOnly"
        />

        <Button
          className="toolbar-button"
          label="I"
          onMouseDown={(event) => {
            call(toggleEmphasisCommand.key);
            event.preventDefault();
          }}
          size="small"
          variant="textOnly"
        />

        <Button
          className="toolbar-button"
          iconLeft="pi pi-minus"
          onMouseDown={(event) => {
            call(toggleStrikethroughCommand.key);
            event.preventDefault();
          }}
          size="small"
          variant="textOnly"
        />

        <Button
          className="toolbar-button"
          label="Quote"
          onMouseDown={(event) => {
            call(wrapInBlockquoteCommand.key);
            event.preventDefault();
          }}
          size="small"
          variant="textOnly"
        />
      </div>
    </div>
  );
};
