import {
  Service,
  SERVICE_STATUS,
  ServiceCreateInput,
  ServiceUpdateInput,
} from "core";

import baseApi, { TagsEnum } from "./base-api";
import { ServicePresetTemplate } from "../../type";

const servicesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAttachedReleases: build.query<
      any,
      { workspaceId: number; serviceSecondaryIdentifier: string }
    >({
      query: ({ workspaceId, serviceSecondaryIdentifier }) => {
        return {
          url: `workspaces/${workspaceId}/services/${serviceSecondaryIdentifier}/releases`,
        };
      },
      providesTags: [TagsEnum.Services],
    }),
    getServices: build.query<Service[], { workspaceId: number }>({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services`,
        };
      },
      providesTags: [TagsEnum.Services],
    }),

    getServicePresetTemplates: build.query<
      ServicePresetTemplate[],
      { workspaceId: number }
    >({
      query: ({ workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/preset-templates`,
        };
      },
      providesTags: [TagsEnum.Services],
    }),

    createService: build.mutation<Service, ServiceCreateInput>({
      query: (data: ServiceCreateInput) => {
        return {
          url: `workspaces/${data.workspaceId}/services`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [TagsEnum.Services],
    }),

    stopService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/stop`,
        };
      },
      async onQueryStarted(row, { dispatch, queryFulfilled, getState }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex((item) => item.id === row.id);

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.STOPPING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deployService: build.query<any, { id: number; workspaceId: number }>({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.id}/deploy`,
        };
      },
      async onQueryStarted(row, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: row.workspaceId },
            (draft) => {
              const index = draft.findIndex((item) => item.id === row.id);

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.STARTING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteService: build.mutation<any, { id: number; workspaceId: number }>({
      query: ({ id, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${id}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(deletedRow, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: deletedRow.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === deletedRow.id,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  statusId: SERVICE_STATUS.DELETING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getServiceBySecondaryIdentifier: build.query<
      Service,
      { secondaryId: string; workspaceId: number }
    >({
      query: ({ secondaryId, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${secondaryId}`,
        };
      },
    }),

    updateService: build.mutation<
      Service,
      { id: number; data: ServiceUpdateInput; workspaceId: number }
    >({
      query: ({ id, data, workspaceId }) => {
        return {
          url: `workspaces/${workspaceId}/services/${id}`,
          method: "PUT",
          body: data,
        };
      },
      async onQueryStarted(updatedRow, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          servicesApi.util.updateQueryData(
            "getServices",
            { workspaceId: updatedRow.workspaceId },
            (draft) => {
              const index = draft.findIndex(
                (item) => item.id === updatedRow.id,
              );

              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  ...(updatedRow.data.name && { name: updatedRow.data.name }),
                  isPublic: updatedRow.data.isPublic,
                  ...(updatedRow.data.volumeMounts && {
                    volumeMounts: updatedRow.data.volumeMounts,
                  }),
                  ...(updatedRow.data.envVars && {
                    envVars: updatedRow.data.envVars,
                  }),
                  statusId: SERVICE_STATUS.UPDATING,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getServiceLogs: build.query<
      any,
      { workspaceId: number; serviceId: number }
    >({
      query: (input) => {
        return {
          url: `workspaces/${input.workspaceId}/services/${input.serviceId}/logs`,
        };
      },
    }),
  }),
});

export const {
  useCreateServiceMutation,
  useLazyGetServicePresetTemplatesQuery,
  useLazyGetServicesQuery,
  useLazyGetServiceBySecondaryIdentifierQuery,
  useLazyGetAttachedReleasesQuery,
  useLazyStopServiceQuery,
  useLazyDeployServiceQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useLazyGetServiceLogsQuery,
} = servicesApi;
export default servicesApi;
